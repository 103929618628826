<template>
  <div :class="dark ? 'bgDark' : 'bgLight'" class="pBot80">
    <div class="pTop80 grid middleMargin" :class="$store.state.screenSize==='mobile'?'singleColumn gap20':$store.state.screenSize==='desktop'?'twoColumns w1300':['laptop','tabletL'].includes($store.state.screenSize)?'w1000':''">
      <img class="w80per grid-element-span2-column middleMargin" :src="logo" alt="Logo">
      <img :class="largeImage || $store.state.screenSize === 'mobile' ? 'w100per' : 'w80per'" :src="image" alt="Image">
      <div class="grid leftText gap0 singleColumn">
        <h3 class="largeText InterMedium orangeText">{{ slogan }}</h3>
        <p class="text InterMedium m0" :class="$store.state.screenSize === 'mobile'?'p30':''">{{ text0 }}</p>
        <ul>
          <li class="text p15" v-for="listItem in list" v-bind:key="listItem">
            {{listItem}}
          </li>
        </ul>
        <p class="text InterMedium">{{text1}}</p>
        <read-more-button style="height: 50px" :text="$store.state.locale.current.rmb2" color="black" :bordered="true" @click="$emit('clicked')" :orange="true"/>
      </div>
    </div>
  </div>
</template>

<script>
import ReadMoreButton from "@/components/ReadMoreButton.vue";

export default {
  name: "AdVertisement",
  components: {ReadMoreButton},
  props: {
    logo: {
      type: String,
      required: false
    },
    slogan: {
      type: String,
      required: true
    },
    text0: {
      type: String,
      default: ''
    },
    text1: {
      type: String,
      default: ''
    },
    list:{
      type:Array,
      default: () => []
    },
    image: {
      type: String,
      required: true
    },
    gallery: {
      type: Array,
      default: () => []
    },
    listItems: {
      type: Array,
      default: () => []
    },
    dark: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: undefined
    },
    largeImage: {
      type: Boolean,
      default: false
    },

  }
}
</script>
