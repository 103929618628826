<template>
  <div :class="[$store.state.screenSize,'bottleProductionMain', {bgSize70Per:['laptop','tabletL'].includes($store.state.screenSize)},{bgSizeContain:$store.state.screenSize === 'desktop'}]">
    <div :class="[$store.state.screenSize,'innerContainer', {w1000:['laptop','tabletL'].includes($store.state.screenSize)},{w1300:$store.state.screenSize === 'desktop'}]">
      <div class="leftSide">
        <TextWithLineAfter :text="gl('bpm0')" color="black" line-position="below"></TextWithLineAfter>
          <h2 class="pageTitle">
            {{gl('bpm1')}}<br>{{gl('bpm2')}}
          </h2>
      </div>
      <div class="rightSide" :class="[{mTop80:['laptop','tabletL'].includes($store.state.screenSize)}]">
        <div class="articles">
          <div class="article" v-for="article in articles" v-bind:key="article.id">
            <h5 class="title">{{article.title}}</h5>
            <p class="text">{{article.text}}</p>
            <read-more-button :text="article.linkText" :goto="article.goto" :identification="article.id" color="black"/>
          </div>
        </div>
      </div>
      </div>
    </div>
</template>
<script>
import TextWithLineAfter from "@/components/TextWithLineAfter.vue";
import ReadMoreButton from "@/components/ReadMoreButton.vue";

export default {
  name:'BottleProductionMain',
  components: {ReadMoreButton, TextWithLineAfter},
  data(){
    return{
    }
  },
  computed:{
    articles(){return [
      {
        title:this.gl('bpm3'),
        text:this.gl('bpm4'),
        linkText:this.gl('bpm5'),
        id:'flakongyartas1',
        goto:'flakongyartas'
      },
      {
        title:this.gl('bpm6'),
        text:this.gl('bpm7'),
        linkText:this.gl('bpm8'),
        id:'flakongyartas2',
        goto:'webshop'
      },
      {
        title:this.gl('bpm9'),
        text:this.gl('bpm10'),
        linkText:this.gl('bpm11'),
        id:'flakongyartas3',
        goto:'flakongyartas'
      },
    ]}
  },
  methods:{
    gl(what){
      return this.$store.state.locale.current[what]
    }
  }
}

</script>
<style scoped lang="scss">
.bottleProductionMain{
  padding: 5%;
  padding-top: unset;

  background-image: url("/public/kek_flakon_frocskolo_viz.jpeg");
  background-repeat: no-repeat;
    .innerContainer{
      margin: 0 auto;
      display: grid;
      grid-template-columns: 70% auto;
      .leftSide{
        .pageTitle{
          font-family: Inter-ExtraBold,sans-serif;
          text-transform: uppercase;
          font-size: var(--titleFont);
          text-align: left;
          width: 900px;
          mix-blend-mode: difference;
          color: #FFF;
        }
      }
      .rightSide{
        margin-top: 200px;
        .articles{
          display: grid;
          height: min-content;
          justify-self: end;
          .article {
            border-top: 1.5px var(--orange-color) solid;
            text-align: left;
            padding-bottom: 2%;
            .title {
              text-transform: uppercase;
              letter-spacing: -.02em;
              font-size: var(--largeTextFont);
              margin-top: 5%;
              margin-bottom: 1%;
            }
            .text{
              width: 90%;
              font-size: var(--textFont);
              margin-bottom: 7%;
              margin-top: 3%;
            }
          }
        }
      }
    }
  &.mobile,
  &.tablet{
    margin-top: 180px;
    height: unset;
    .innerContainer{
      width: 100%;
      grid-template-columns: 1fr;
      .leftSide{
        width: 85%;
        .pageTitle{
          width: 100%;
          padding-top: 130px;
          margin-bottom: unset;
        }
      }
      .rightSide{
        width: 85%;
        margin-top: 100px;
        .article{
          padding-bottom: 50px;
        }
      }
    }
  }
  &.tablet{
    .innerContainer {
      .rightSide {
        width: 60%;
        justify-self: right;
      }
    }
  }
}
</style>