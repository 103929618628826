<template>
  <div class="flex flex-wrap-wrap align-items-center justify-center">
    <span
        v-for="(item, index) in pathItems"
        :key="index"
        class="InterMedium capital no-wrap text"
    >
      {{ item }}
      <span v-if="index < pathItems.length - 1" class="slash orangeText">/</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'PathComponent',
  props: {
    pathItems: {
      type: Array,
      required: true
    }
  }
}
</script>
<style scoped>
.slash {
  margin: 0 8px;
}
</style>