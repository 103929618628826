<template>
  <div class="language-chooser pos-rel pointer w-max">
    <div
        class="selected-language flex align-items-center p-15 bg-light-grey tr-1s"
        @mouseover="showDropdown = true"
        @mouseleave="hideDropdown"
    >
      <img class="h-35px w-35px" :src="selectedFlag" alt="Selected Language">
      <span class="text m-left-15 fw-700">{{ selectedLanguage }}</span>
      <div
          v-if="showDropdown"
          class="dropdown flex-column pos-abs top-100p bg-white z-100 tr-1s p15 w-max"
          @mouseover="showDropdown = true"
          @mouseleave="hideDropdown"
      >
        <div
            class="dropdown-item flex align-items-center p-15 pointer"
            v-for="(flag, lang) in languages"
            :key="lang"
            @click="selectLanguage(lang)"
        >
          <img class="h-35px w-35px" :src="flag" :alt="lang">
          <span class="text m-left-15 fw-700">{{ lang.toUpperCase() }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LanguageChooser',
  data() {
    return {
      selectedLanguage: 'HU',
      selectedFlag: require('@/assets/flags/hu.png'), // Update the path to your flag assets
      showDropdown: false,
      languages: {
        hu: require('@/assets/flags/hu.png'), // Update the path to your flag assets
        en: require('@/assets/flags/en.png'), // Update the path to your flag assets
        ro: require('@/assets/flags/ro.png')  // Update the path to your flag assets
      }
    };
  },
  methods: {
    hideDropdown() {
      this.showDropdown = false;
    },
    selectLanguage(lang) {
      this.selectedLanguage = lang.toUpperCase();
      this.selectedFlag = this.languages[lang];
      this.$emit('languageSelected', lang);
      this.$store.commit('setLanguage',lang)
      this.showDropdown = false;
    }
  }
};
</script>


<style scoped>
.dropdown {
  animation: dropdownIn 0.3s ease-out;
}

@keyframes dropdownIn {
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
