<template>
  <button class="close-button" @click="emitClose">
    <svg
        class="close-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
    >
      <path
          d="M18 6L6 18M6 6l12 12"
          stroke="currentColor"
          stroke-width="4"
          stroke-linecap="round"
          stroke-linejoin="round"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: 'CloseButton',
  methods: {
    emitClose() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.close-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  outline: none;
  transition: transform 0.2s ease, background-color 0.2s ease;
  border-radius: 50%; /* Optional: makes the button round */
}

.close-button:hover {
  transform: scale(1.2);
  background-color: rgba(0, 0, 0, 0.1); /* Optional: light background on hover */
}

.close-icon {
  width: 24px;
  height: 24px;
  display: block;
}
</style>
