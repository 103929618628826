<template>
  <div :class="$store.state.screenSize + ' blowingMachineWireFrameDesign'">
    <div id="innerContainer" :class="[{w1300: $store.state.screenSize ==='desktop'},$store.state.screenSize === 'laptop'&&'w1000',$store.state.screenSize === 'tabletL'&&'w800']">
      <TextWithLineAfter :text="gl('bmwfd1')" color="rgb(21,20,28)" line-position="below"></TextWithLineAfter>
      <div id="top" :class="[$store.state.screenSize === 'tabletL'&&'flex-column']">
        <h3 class="title" :class="$store.state.screenSize === 'tabletL'&&'w100per'">{{gl('bmwfd2')}}</h3>
        <p :class="$store.state.screenSize === 'tabletL'&&'w100per'">{{gl('bmwfd3')}}</p>
      </div>
      <div id="bottom">
        <img src="fuvogep_wireframe.webp">
        <MapMarker v-for="marker in mapMarkers" v-bind:key="marker" :markerData="marker" color="black" class="blowingMachineMarker" popupBackground="white"/>
      </div>
    </div>
  </div>
</template>
<script>
import TextWithLineAfter from "@/components/TextWithLineAfter.vue";
import MapMarker from "@/components/mapMarker.vue";

export default {
  name: "BlowingMachineWireFrameDesign",
  components: {MapMarker, TextWithLineAfter},
  data(){
    return{
      mapMarkers:[
        {
          xCoordinate:1087,
          yCoordinate:330,
          country: 'Robosztus váz',
          code:'bm0'
        },
        {
          xCoordinate:630,
          yCoordinate:380,
          country: 'Szilárd záró szerszám',
          code:'bm1'
        },
        {
          xCoordinate:700,
          yCoordinate:250,
          country: 'Szervo motorokkal vezérelt flakongyártás',
          code:'bm2'
        },
        {
          xCoordinate:700,
          yCoordinate:410,
          country: '40 Bar-ig növelhető pneumatikai rendszer',
          code:'bm3'
        },
        {
          xCoordinate:700,
          yCoordinate:410,
          country: 'Levegővisszanyerő rendszer',
          code:'bm4'
        },
        {
          xCoordinate:700,
          yCoordinate:410,
          country: 'Gyors átállási lehetőség',
          code:'bm5'
        },
      ]
    }
  },
  methods:{
    gl(what){
      return this.$store.state.locale.current[what]
    }
  }
}
</script>
<style scoped lang="scss">
  .blowingMachineWireFrameDesign{
    #innerContainer{
      margin: 0 auto;
      #top{
        display: flex;
        flex-direction: row;;
        width: 100%;
        height: 40%;
        padding-top: 50px;
        .title{
          width: 50%;
          font-family: Inter-ExtraBold,sans-serif;
          font-size: var(--titleFont);
          text-transform: uppercase;
          text-align: left;
          margin: unset;
          color: var(--orange-color);
        }
        p{
          width: 50%;
          text-align: left;
          line-height: 30px;
          font-size: var(--largeTextFont);
          margin: unset;
        }
      }
      #bottom{
        position: relative;
        .markerContainer{
          //transform: scale(1.9);
        }
        img{
          width: 80%;
        }
      }
    }
    &.mobile,
    &.tablet{
      height: unset;
      padding-top: 80px;
      #innerContainer{
        width: 85%;
        #top{
          padding-top: unset;
          flex-direction: column;
          .title{
            width: 100%;
          }
          p{
            width: 100%;
            margin-top: 30px;
          }
        }
        #bottom{
          img{
            width: 110%;
          }
          .blowingMachineMarker{
            display: none;
          }
        }
      }
    }
  }
</style>