<script setup>
import NavBar from "@/components/NavBar.vue";
//import EnquiryButtonSideFloat from "@/components/enquiryButtonSideFloat.vue";
import EnquiryForm from "@/components/EnquiryForm.vue";

function onScroll(){
  if (document.getElementById('routerView').scrollTop > 800) {
    document.getElementById("navbar").style.height = "6dvh";
    document.getElementById("routerView").style.marginTop = "0dvh";
  }
  else {
    document.getElementById("navbar").style.height = "8dvh";
    document.getElementById("routerView").style.marginTop = "0dvh";
    document.getElementById("routerView").style.marginTop = "8dvh";
  }
}

</script>
<template>
  <NavBar id="navbar" :class="$store.state.screenSize" :style="$store.state.sharedStyle" height="8dvh" :sites="$store.state.sites"
  ></NavBar>
  <router-view id="routerView" :style="$store.state.sharedStyle" @scroll="onScroll" @click="$store.state.navOpen? $store.state.navOpen = false:''" :class="$store.state.screenSize"/>
  <!--<enquiry-button-side-float @click="$store.commit('setFormVisibility')"/>-->
  <enquiry-form v-if="$store.state.showTheForm" @close="$store.commit('setFormVisibility')" />
  <img class="pos-fix w270 bot-0 rig-0" src="szechenyi_plus_eu-2.png">
</template>
<script>
export default {
  created() {
    this.updateScreenSize();
    window.addEventListener('resize', this.updateScreenSize);
    this.$store.state.chosenLang === undefined && this.$store.commit('setLanguage','hu')
  },

  methods:{
    updateScreenSize() {
      if(window.innerWidth <= 480){
        this.$store.dispatch('overallSetting','mobile')
      }
      else if(window.innerWidth <= 768){
        this.$store.dispatch('overallSetting','mobileL')
      }
      else if(window.innerWidth <= 1024){
        this.$store.dispatch('overallSetting','tabletP')
      }
      else if(window.innerWidth <= 1200){
        this.$store.dispatch('overallSetting','tabletL')
      }
      else if(window.innerWidth <= 1440){
        this.$store.dispatch('overallSetting','laptop')
      }
      else{
        this.$store.dispatch('overallSetting','desktop')
      }
    },
  },
}
</script>


<style lang="scss">
@import "./assets/globalStyle.scss";

body{
  margin: unset !important;
}
#app {
  --dark-font-color:#2c3e50;
  --orange-color: #F67013;
  --dark-background-color: rgb(21,20,28);

  //FONTOK
  @font-face {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("/public/font/Inter-Regular.woff2") format("woff2");
  }
  @font-face {
    font-family: "Space-Grotesk";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("/public/font/SpaceGrotesk-VariableFont_wght.ttf") format("woff2");
  }
  @font-face { font-family: "Inter-ExtraBold";
    font-style: normal;
    font-weight: 4800;
    font-display: swap;
    src: url("/public/font/Inter-ExtraBold.woff2") format("woff2");
  }
  @font-face { font-family: "Inter-Medium";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("/public/font/Inter-Medium.woff2") format("woff2");
  }


  :root { font-family: 'Inter', sans-serif; }
  @supports (font-variation-settings: normal) {
    :root { font-family: 'Inter var', sans-serif; }
  }

  //font-family: Avenir, Helvetica, Arial, sans-serif;
  //font-family: proxima-nova, sans-serif;
  font-family: 'Inter', sans-serif;
  //font-family: proxima-nova, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color:var(--dark-font-color);
  background-color: white;
  height: 100dvh;
  //width: 100vw;
  margin: 0 auto;
  overflow: hidden;
}
#routerView{
  margin-top: 8dvh;
  height: 100dvh;
  overflow: scroll;
  overflow-x: hidden;
}




</style>
