<template>
  <ul class="list-none">
    <li
        v-for="item in items"
        :key="item"
        class="p-10px-0-10px-20px pos-relative content-backslash leftText text fw-500"
    >
      {{ item }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'PoliolList',
  props: {
    items: {
      type: Array,
      required: true
    }
  }
};
</script>