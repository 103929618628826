<template>
  <div class="navBar" :style="CSSProps">
    <img class="logo" src="poliol_logo.webp" @click="$store.dispatch('navigateTo','home')">
    <div style="width: 100%; display: flex; height: 100%" v-if="$store.state.screenSize !== 'mobile'">
      <nav-bar-item v-for="site in this.sites" v-bind:key="site.url" :id="'nav_'+site.url" @click="$store.dispatch('navigateTo',site.url)" :text="site.text" :class="{chosen:$store.state.chosenTab === site.url}"/>
    </div>
    <hamburger-menu
        v-else
        class="bigMac"
        :items="$store.state.sites"
    ></hamburger-menu>
    <LanguageChooser v-if="$store.state.screenSize !== 'mobile'" class="m-rig-30" />
  </div>
</template>
<script>

import NavBarItem from "@/components/NavBarItem.vue";
import HamburgerMenu from "@/components/Hamburger-menu.vue";
import LanguageChooser from "@/components/LanguageChooser.vue";

export default {
  name: 'NavBar',
  components: {LanguageChooser, HamburgerMenu, NavBarItem},
  props:['height','sites'],
  computed:{
    CSSProps(){
      return{
        '--height':this.height
      }
    }
  },
  mounted() {
    let nav_home = document.getElementById('nav_home')
    if(nav_home){
      nav_home.classList.add('chosen');
    }

  },
  methods:{
    goto(url){
      //document.getElementsByClassName('chosen')[0].classList.remove('chosen')
      //document.getElementById('nav_'+url).classList.add('chosen');
      this.$router.replace({name:url})
    }
  }
}

</script>
<style scoped lang="scss">
.navBar{
  transition: .6s ease;
  background-color: #F9F9F9;
  position: fixed;
  height: var(--height,7dvh);
  width: 100vw;
  display: flex;
  align-items: center;
  z-index: 100;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
  0 6.7px 5.3px rgba(0, 0, 0, 0.048),
  0 12.5px 10px rgba(0, 0, 0, 0.06),
  0 22.3px 17.9px rgba(0, 0, 0, 0.072),
  0 41.8px 33.4px rgba(0, 0, 0, 0.086),
  0 100px 80px rgba(0, 0, 0, 0.12);

  .logo {
    height: 50%;
    padding: 1%;
    margin-right: 5%;
  }
  &.mobile,
  &.tablet{
    .logo{
      margin: 0 auto;
      height: 80%;
    }
  }
}
</style>