<script>
import {AwesomeSocialButton} from "awesome-social-button";

export default {
  name: "SocialMedia",
  components: {AwesomeSocialButton},
}
</script>

<template>
<div id="socialMediaContainer">
  <awesome-social-button
      type="facebook"
      :link="{ src: 'https://facebook.com'}"
      shape="square"
      tooltip="Facebook"
      :width="60"
  />
  <awesome-social-button
      type="instagram"
      :link="{ src: 'https://facebook.com'}"
      shape="square"
      tooltip="Instagram"
      :width="60"
  />
  <awesome-social-button
      type="linkedin"
      :link="{ src: 'https://facebook.com'}"
      shape="square"
      tooltip="LinkedIn"
      :width="60"
  />
  <awesome-social-button
      type="youtube"
      :link="{ src: 'https://facebook.com'}"
      shape="square"
      tooltip="YouTube"
      :width="60"

  />
</div>
</template>

<style scoped lang="scss">
  #socialMediaContainer{
    width: min-content;
    display: grid;
    //gap: 1.5rem;
    grid-template-columns: repeat(4, auto);
    grid-template-rows: auto;
    .awesome-social-button{
      background: transparent;
      box-shadow: unset;
      color: var(--orange-color);
    }
  }
</style>