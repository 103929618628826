import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProductPage from "@/views/ProductPage.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/blowing-machine-production',
    name: 'blowingMachine',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BlowingMachineMainView.vue')
  },
  {
    path: '/bucket-production',
    name: 'bucket',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BucketsMainView.vue')
  },
  {
    path: '/szerelekgyartas',
    name: 'szerelekgyartas',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BucketsMainView.vue')
  },
  {
    path: '/flakonfuvo-gepgyartas',
    name: 'flakonfuvogep',

    component: () => import(/* webpackChunkName: "about" */ '../views/BlowingMachineMainView.vue')
  },
  {
    path: '/flakongyartas',
    name: 'flakongyartas',

    component: () => import(/* webpackChunkName: "about" */ '../views/BottlesMainView.vue')
  },
  {
    path: '/szerelekek/:idOfProd',
    name: 'ProductPage',
    props: true, // This allows us to pass the route params as props to the component
    component: ProductPage
  }
]

const base = process.env.NODE_ENV === 'production' ? '/' : '/';


const router = createRouter({
  history: createWebHistory(base),
  routes
})

export default router
