<template>
  <div class="">
    <div class="middleMargin flex-column" :class="[{p15: $store.state.screenSize==='mobile'},{w1300:$store.state.screenSize==='desktop'},{w1000:['laptop','tabletL'].includes($store.state.screenSize)}]">
      <path-component class="middleMargin p-top-80" :path-items="['POLIOL',category,typeName]"/>
      <h2 class="smallTitle capital InterExtraBold">{{title}}</h2>
      <div class="grid twoColumns " :class="$store.state.screenSize === 'mobile'? 'singleColumn m-top-15 gap20':'m-top-80'">
        <div>
          <img :src="require('@/assets/products/'+mainImage)" class="w80per">
        </div>
        <div class="leftText">
          <h2 class="xLargeText capital InterMedium" :class="$store.state.screenSize === 'mobile'?'centerText':''">{{gl('pp1')}}</h2>
          <PoliolList :items="properties"/>
          <enquiry-button :text="gl('pp2')" class="m-top-40" @click="scrollToComponent('enquiry')" />
        </div>
      </div>
      <p class="largeText leftText m-top-80 line-height-1_4">{{text}}</p>
      <h4 class="xSmallTitle leftText m0 m-top-80">{{gl('pp3')}}</h4>
      <p class="leftText largeText w80per">{{usage}}</p>
      <img v-if="sideImage !== undefined" class="w80per middleMargin" :src="sideImage">
      <h4 v-if="noOfRefPics !== undefined" class="xSmallTitle leftText m0 m-top-80">{{gl('pp4')}}</h4>
      <PhotoGallery v-if="noOfRefPics !== undefined" :noOfSlides="noOfRefPics" :idOfProd="idOfProd"/>
    </div>
    <ContactUsPage
        title="Keressen minket!"
        subtitle="Forduljon hozzánk bizalommal"
        contact-email="tosi.janos@poliol.hu"
        contact-phone="06 20 342 2988"
        :contact-topic="'szerelékgyártás - ' +this.title"
        class="m-bot-80"/>
    <FooterComp/>
  </div>
</template>

<script>
import PathComponent from "@/components/PathComponent.vue";
import PoliolList from "@/components/Poliol-list.vue";
import EnquiryButton from "@/components/EnquiryButton.vue";
import FooterComp from "@/components/FooterComp.vue";
import ContactUsPage from "@/components/ContactUsPage.vue";
import PhotoGallery from "@/components/PhotoGallery.vue";

export default {
  name: "ProductPage",
  components: {PhotoGallery, ContactUsPage, FooterComp, EnquiryButton, PoliolList, PathComponent},
  props:['idOfProd'],
  computed:{
    title(){
      return this.$store.getters.getProductTitle(this.idOfProd);
    },
    type(){
      return this.$store.getters.getProductType(this.idOfProd);
    },
    typeName(){
      return this.$store.state.productCategories.find(category => category.name === this.category).types.find(type => type.id === this.type).name
    },
    category(){
      return this.$store.state.productCategories.find(category =>
          category.types.some(type => type.id === this.type)
      ).name;
    },
    mainImage(){
      return this.$store.getters.getProductMainImage(this.idOfProd);
    },
    sideImage(){
      return this.$store.getters.getProductSideImage(this.idOfProd);
    },
    properties(){
      return this.$store.getters.getProductProperties(this.idOfProd);
    },
    usage(){
      return this.$store.getters.getProductUsage(this.idOfProd);
    },
    text(){
      return this.$store.getters.getProductText(this.idOfProd);
    },
    noOfRefPics(){
      return this.$store.getters.getPruductReferencePics(this.idOfProd);
    },
  },
  methods:{
    scrollToComponent(componentId) {
      var element = document.getElementById(componentId);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth', // Smooth scrolling
          block: this.$store.state.screenSize === 'mobile'?'start':'center' // Scroll to the middle of the element
        });

        // Add a class to highlight the element
        element.classList.add('border-color-orange');

        // Remove the class after 3 seconds
        setTimeout(function() {
          element.classList.remove('border-color-orange');
        }, 3000);
      } else {
        console.warn('Element with ID ' + componentId + ' not found.');
      }
    },
    gl(what){
      return this.$store.state.locale.current[what]
    },

  },

}
</script>