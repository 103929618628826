import { createStore } from 'vuex'
import router from '@/router'
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    screenSize: 'mobile',
    navOpen: false,
    sharedStyle: {
      '--titleFont': '60px',
      '--largeTitleFont': '63px',
      '--textFont': '21px',
      '--smallTextFont': '16px',
      '--largeTextFont': '23px',
      '--xLargeTextFont': '28px',
      '--xSmallTitleFont': '45px',
      '--smallTitleFont': '55px'
    },
    sites: [
      {text: 'Főoldal', url: 'home'},
      {text: 'Flakongyártás', url: 'flakongyartas'},
      {text: 'Kanálgyártás', url: 'bucket'},
      {text: 'Flakonfúvó gépgyártás', url: 'flakonfuvogep'},
    ],
    showTheForm: false,
    resetNavbarHeight: false,
    chosenTab: 'home',
    chosenLang: undefined,
    productCategories:
        [],
    products: [
      {
        id: 'rezsu-kanal',
        category: 'buckets',
        type: 'foldmunka',
        image: 'fix-rezsu-kanal.png',
        secondImage: 'rezsu_bontott.png',
        name: 'Rézsűkanál',
        properties: [
          'A vágóél és az oldalél kopásálló anyagból készül',
          'Erősített kanáltest',
          'Igényelhető kétélű csavarozható kopóéllel is',
          'Kérésre vízelvezető furatok kialakítása az oldal és palástlemezen',
          'Hátulra kerülhet simítóél, amivel visszafelé is tud dolgozni'
        ],
        usage: '',
        advantages: ['', ''],
        recommended: '',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
      },
      {
        id: 'hidraulikus-rezsu-kanal',
        category: 'buckets',
        type: 'foldmunka',
        image: 'hidraulikus-rezsu-kanal.png',
        name: 'Hidraulikusan billenthető rézsű kanál',
        properties: [
          'A vágóél és az oldalél kopásálló anyagból készül',
          'Erősített kanáltest',
          'Igényelhető kétélű csavarozható kopóéllel is',
          'Kérésre vízelvezető furatok kialakítása az oldal és palástlemezen',
          'Hátulra kerülhet simítóél, amivel visszafelé is tud dolgozni'
        ],
        usage: '',
        advantages: ['', ''],
        recommended: '',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
      },
      {
        id: 'melyaso-kanal-korom-nelkul',
        category: 'buckets',
        type: 'foldmunka',
        image: 'melyaso-kanal-korom-nelkul.png',
        name: 'Mélyásó kanál vágóéllel',
        properties: [
          'A vágóél és az oldalél kopásálló anyagból készül',
          'Erősített kanáltest',
          'Igényelhető kétélű csavarozható kopóéllel is',
          'Kérésre vízelvezető furatok kialakítása az oldal és palástlemezen',
          'Hátulra kerülhet simítóél, amivel visszafelé is tud dolgozni'
        ],
        usage: 'A mélyásó kanál a közepestől a nehezebb kotrási munkák elvégzésére alkalmas. Minden talajtípushoz eltérő kanál az optimális, így számos változatban érhetők el a mélyásó kanalak. Az elején található fogak a bontást segítik, míg a vágóél a föld ellenállását győzi le.',
        advantages: ['', ''],
        recommended: '',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
      },
      {
        id: 'rezsu-kanal-varos',
        category: 'buckets',
        type: 'varos',
        image: 'fix-rezsu-kanal.png',
        name: 'Rézsűkanál',
        properties: [
          'A vágóél és az oldalél kopásálló anyagból készül',
          'Erősített kanáltest',
          'Igényelhető kétélű csavarozható kopóéllel is',
          'Kérésre vízelvezető furatok kialakítása az oldal és palástlemezen',
          'Hátulra kerülhet simítóél, amivel visszafelé is tud dolgozni'
        ],
        usage: '',
        advantages: ['', ''],
        recommended: '',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
      },
      {
        id: 'melyaso-kanal',
        category: 'buckets',
        type: 'foldmunka',
        image: 'melyaso-kanal-korommel.png',
        name: 'Mélyásó kanál körömmel',
        properties: [
          'A vágóél és az oldalél kopásálló anyagból készül',
          'Erősített kanáltest',
          'Igényelhető kétélű csavarozható kopóéllel is',
          'Kérésre vízelvezető furatok kialakítása az oldal és palástlemezen',
          'Hátulra kerülhet simítóél, amivel visszafelé is tud dolgozni'
        ],
        usage: 'A mélyásó kanál a közepestől a nehezebb kotrási munkák elvégzésére alkalmas. Minden talajtípushoz eltérő kanál az optimális, így számos változatban érhetők el a mélyásó kanalak. Az elején található fogak a bontást segítik, míg a vágóél a föld ellenállását győzi le.',
        advantages: ['', ''],
        recommended: '',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
      },
      {
        id: 'trapez-kanal-foldmunka',
        category: 'buckets',
        type: 'foldmunka',
        image: 'trapez-kanal.png',
        name: 'Trapéz kanál',
        properties: [
          'A vágóél és az oldalél kopásálló anyagból készül',
          'Erősített kanáltest',
          'Igényelhető kétélű csavarozható kopóéllel is',
          'Kérésre vízelvezető furatok kialakítása az oldal és palástlemezen',
          'Hátulra kerülhet simítóél, amivel visszafelé is tud dolgozni'
        ],
        usage: 'A mélyásó kanál a közepestől a nehezebb kotrási munkák elvégzésére alkalmas. Minden talajtípushoz eltérő kanál az optimális, így számos változatban érhetők el a mélyásó kanalak. Az elején található fogak a bontást segítik, míg a vágóél a föld ellenállását győzi le.',
        advantages: ['', ''],
        recommended: '',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
      },
      {
        id: 'melyaso-kanal-korom-nelkul-kozmu',
        category: 'buckets',
        type: 'kozmu',
        image: 'melyaso-kanal-korom-nelkul.png',
        name: 'Mélyásó kanál vágóéllel',
        properties: [
          'A vágóél és az oldalél kopásálló anyagból készül',
          'Erősített kanáltest',
          'Igényelhető kétélű csavarozható kopóéllel is',
          'Kérésre vízelvezető furatok kialakítása az oldal és palástlemezen',
          'Hátulra kerülhet simítóél, amivel visszafelé is tud dolgozni'
        ],
        usage: 'A mélyásó kanál a közepestől a nehezebb kotrási munkák elvégzésére alkalmas. Minden talajtípushoz eltérő kanál az optimális, így számos változatban érhetők el a mélyásó kanalak. Az elején található fogak a bontást segítik, míg a vágóél a föld ellenállását győzi le.',
        advantages: ['', ''],
        recommended: '',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
      },
      {
        id: 'trapez-kanal-kozmuepites',
        category: 'buckets',
        type: 'kozmu',
        image: 'trapez-kanal.png',
        name: 'Trapéz kanál',
        properties: [
          'A vágóél és az oldalél kopásálló anyagból készül',
          'Erősített kanáltest',
          'Igényelhető kétélű csavarozható kopóéllel is',
          'Kérésre vízelvezető furatok kialakítása az oldal és palástlemezen',
          'Hátulra kerülhet simítóél, amivel visszafelé is tud dolgozni'
        ],
        usage: 'A mélyásó kanál a közepestől a nehezebb kotrási munkák elvégzésére alkalmas. Minden talajtípushoz eltérő kanál az optimális, így számos változatban érhetők el a mélyásó kanalak. Az elején található fogak a bontást segítik, míg a vágóél a föld ellenállását győzi le.',
        advantages: ['', ''],
        recommended: '',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
      },
      {
        id: 'ripper',
        category: 'buckets',
        type: 'foldmunka',
        image: 'ripper.png',
        name: 'Bontófog',
        properties: [
          'A vágóél és az oldalél kopásálló anyagból készül',
          'Erősített kanáltest',
          'Igényelhető kétélű csavarozható kopóéllel is',
          'Kérésre vízelvezető furatok kialakítása az oldal és palástlemezen',
          'Hátulra kerülhet simítóél, amivel visszafelé is tud dolgozni'
        ],
        usage: '',
        advantages: ['', ''],
        recommended: '',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
      },
      {
        id: 'hidraulikus-rezsu-kanal-kozmu',
        category: 'buckets',
        type: 'kozmu',
        image: 'hidraulikus-rezsu-kanal.png',
        name: 'Hidraulikusan billenthető rézsűkanál',
        properties: [
          'A vágóél és az oldalél kopásálló anyagból készül',
          'Erősített kanáltest',
          'Igényelhető kétélű csavarozható kopóéllel is',
          'Kérésre vízelvezető furatok kialakítása az oldal és palástlemezen',
          'Hátulra kerülhet simítóél, amivel visszafelé is tud dolgozni'
        ],
        usage: '',
        advantages: ['', ''],
        recommended: '',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
      },
      {
        id: 'erositett-melyaso-kanal',
        category: 'buckets',
        type: 'banyaszat',
        image: 'erositett-melyaso-kanal.png',
        name: 'Erősített mélyásó kanál (HDX)',
        properties: [
          'A vágóél és az oldalél kopásálló anyagból készül',
          'Erősített kanáltest',
          'Igényelhető kétélű csavarozható kopóéllel is',
          'Kérésre vízelvezető furatok kialakítása az oldal és palástlemezen',
          'Hátulra kerülhet simítóél, amivel visszafelé is tud dolgozni'
        ],
        usage: '',
        advantages: ['', ''],
        recommended: '',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
      },
      {
        id: 'erositett-rakodo-kanal',
        category: 'buckets',
        type: 'banyaszat',
        image: 'erositett-rakodo-kanal.png',
        name: 'Erősített rakodókanál (HDX)',
        properties: [
          'A vágóél és az oldalél kopásálló anyagból készül',
          'Erősített kanáltest',
          'Igényelhető kétélű csavarozható kopóéllel is',
          'Kérésre vízelvezető furatok kialakítása az oldal és palástlemezen',
          'Hátulra kerülhet simítóél, amivel visszafelé is tud dolgozni'
        ],
        usage: '',
        advantages: ['', ''],
        recommended: '',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
      },
      {
        id: 'rakodo-kanal',
        category: 'buckets',
        type: 'varos',
        image: 'rakodo-kanal.png',
        name: 'Rakodókanál',
        properties: [
          'A vágóél és az oldalél kopásálló anyagból készül',
          'Erősített kanáltest',
          'Igényelhető kétélű csavarozható kopóéllel is',
          'Kérésre vízelvezető furatok kialakítása az oldal és palástlemezen',
          'Hátulra kerülhet simítóél, amivel visszafelé is tud dolgozni'
        ],
        usage: '',
        advantages: ['', ''],
        recommended: '',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
      },
      {
        id: 'ripper-banyaszat',
        category: 'buckets',
        type: 'banyaszat',
        image: 'ripper.png',
        name: 'Bontófog',
        properties: [
          'A vágóél és az oldalél kopásálló anyagból készül',
          'Erősített kanáltest',
          'Igényelhető kétélű csavarozható kopóéllel is',
          'Kérésre vízelvezető furatok kialakítása az oldal és palástlemezen',
          'Hátulra kerülhet simítóél, amivel visszafelé is tud dolgozni'
        ],
        usage: '',
        advantages: ['', ''],
        recommended: '',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
      },
      {
        id: 'hidraulikus-kotro-XCMG-gyorscserelo',
        category: 'buckets',
        type: 'gyorscserelok',
        image: 'hidraulikus-kotro-xcmg-gyorscserelo.png',
        name: 'Hidraulikus Kotró gyorscserélő - XCMG',
        properties: [
          'A vágóél és az oldalél kopásálló anyagból készül',
          'Erősített kanáltest',
          'Igényelhető kétélű csavarozható kopóéllel is',
          'Kérésre vízelvezető furatok kialakítása az oldal és palástlemezen',
          'Hátulra kerülhet simítóél, amivel visszafelé is tud dolgozni'
        ],
        usage: '',
        advantages: ['', ''],
        recommended: '',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
      },
      {
        id: 'hidraulikus-rakodo-VOLVO-gyorscserelo',
        category: 'buckets',
        type: 'gyorscserelok',
        image: 'hidraulikus-rakodo-volvo-gyorscserelo.png',
        name: 'Hidraulikus Rakodó gyorscserélő',
        properties: [
          'A vágóél és az oldalél kopásálló anyagból készül',
          'Erősített kanáltest',
          'Igényelhető kétélű csavarozható kopóéllel is',
          'Kérésre vízelvezető furatok kialakítása az oldal és palástlemezen',
          'Hátulra kerülhet simítóél, amivel visszafelé is tud dolgozni'
        ],
        usage: '',
        advantages: ['', ''],
        recommended: '',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
      },
      {
        id: 'balavilla',
        category: 'buckets',
        type: 'faipar',
        image: 'balavilla.png',
        name: 'Bálavilla',
        properties: [
          'A vágóél és az oldalél kopásálló anyagból készül',
          'Erősített kanáltest',
          'Igényelhető kétélű csavarozható kopóéllel is',
          'Kérésre vízelvezető furatok kialakítása az oldal és palástlemezen',
          'Hátulra kerülhet simítóél, amivel visszafelé is tud dolgozni'
        ],
        usage: '',
        advantages: ['', ''],
        recommended: '',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.',
        noOfRefPics: 1
      },

      {
        id: 'tragyavilla',
        category: 'buckets',
        type: 'faipar',
        image: 'tragyavilla.png',
        name: 'Trágyavilla',
        properties: [
          'A vágóél és az oldalél kopásálló anyagból készül',
          'Erősített kanáltest',
          'Igényelhető kétélű csavarozható kopóéllel is',
          'Kérésre vízelvezető furatok kialakítása az oldal és palástlemezen',
          'Hátulra kerülhet simítóél, amivel visszafelé is tud dolgozni'
        ],
        usage: '',
        advantages: ['', ''],
        recommended: '',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
      },
      {
        id: 'konnyuanyag-kanal',
        category: 'buckets',
        type: 'faipar',
        image: 'konnyuanyag-kanal.png',
        name: 'Könnyűanyag kanál',
        properties: [
          'A vágóél és az oldalél kopásálló anyagból készül',
          'Erősített kanáltest',
          'Igényelhető kétélű csavarozható kopóéllel is',
          'Kérésre vízelvezető furatok kialakítása az oldal és palástlemezen',
          'Hátulra kerülhet simítóél, amivel visszafelé is tud dolgozni'
        ],
        usage: '',
        advantages: ['', ''],
        recommended: '',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
      },
      {
        id: 'magasuritesu-kanal',
        category: 'buckets',
        type: 'faipar',
        image: 'magasuritesu-kanal.png',
        name: 'Magasürítésű kanál',
        properties: [
          'A vágóél és az oldalél kopásálló anyagból készül',
          'Erősített kanáltest',
          'Igényelhető kétélű csavarozható kopóéllel is',
          'Kérésre vízelvezető furatok kialakítása az oldal és palástlemezen',
          'Hátulra kerülhet simítóél, amivel visszafelé is tud dolgozni'
        ],
        usage: '',
        advantages: ['', ''],
        recommended: '',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
      },
      {
        id: 'raklapvilla',
        category: 'buckets',
        type: 'banyaszat',
        image: 'raklapvilla.png',
        name: 'Raklapvilla',
        properties: [
          'A vágóél és az oldalél kopásálló anyagból készül',
          'Erősített kanáltest',
          'Igényelhető kétélű csavarozható kopóéllel is',
          'Kérésre vízelvezető furatok kialakítása az oldal és palástlemezen',
          'Hátulra kerülhet simítóél, amivel visszafelé is tud dolgozni'
        ],
        usage: '',
        advantages: ['', ''],
        recommended: '',
        text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
      },


      //BLOWING MACHINES
      {
        id: 'pap1eco',
        category: 'blowingMachine',
        name: 'PAP-1 ECO',
        image: 'PAP-1-ECO.jpg',
        values: ['1', '0.75 L', '800'],
        overlayText: ' ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        id: 'pap1',
        category: 'blowingMachine',
        name: 'PAP-1',
        image: 'PAP-1.jpg',
        values: ['1', '0.75 L', '900'],
        overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        id: 'pap1hotFill',
        category: 'blowingMachine',
        name: 'PAP-1 HOT FILL',
        image: 'PAP-1.jpg',
        values: ['1', '0.75 L', '650'],
        overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        id: 'pa1eco',
        category: 'blowingMachine',
        name: 'PA-1 ECO',
        image: 'dummy_300x150.png',
        values: ['1', '2.25 L', '800'],
        overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        id: 'pa1hotFill',
        category: 'blowingMachine',
        name: 'PA-1 HOT FILL',
        image: 'dummy_300x150.png',
        values: ['1', '2.25 L', '650'],
        overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        id: 'pe15uni',
        category: 'blowingMachine',
        name: 'PE-15 UNI',
        image: 'PE-15-UNI.jpg',
        values: ['1', '6 L', '1300'],
        overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        id: 'pe110uni',
        category: 'blowingMachine',
        name: 'PE-110 UNI',
        image: 'PE-110-UNI.jpg',
        values: ['1', '12 L', '1200',],
        overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        id: 'pap2',
        category: 'blowingMachine',
        name: 'PAP-2',
        image: 'PAP-1.jpg',
        values: ['2', '0.3 L', '1800',],
        overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        id: 'pa2ecoplus',
        category: 'blowingMachine',
        name: 'PA-2 ECO+',
        image: 'PA-2-ECO.jpg',
        values: ['2', '2 L', '1400',],
        overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        id: 'pe2uni',
        category: 'blowingMachine',
        name: 'PE-2 UNI',
        image: 'PE-2-UNI-és-PE-2-UNI-HOTFILL.jpg',
        values: ['2', '2.5 L', '3200',],
        overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        id: 'pe2uniHotFill',
        category: 'blowingMachine',
        name: 'PE-2 UNI HOT FILL',
        image: 'PE-2-UNI-és-PE-2-UNI-HOTFILL.jpg',
        values: ['2', '2.5 L', '1800',],
        overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        id: 'pe25uni',
        category: 'blowingMachine',
        name: 'PE-25 UNI',
        image: 'PE-25-UNI-és-PA-210.jpg',
        values: ['2', '6 L', '2500',],
        overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        id: 'pa210uni',
        category: 'blowingMachine',
        name: 'PA-210 UNI',
        image: 'PE-25-UNI-és-PA-210.jpg',
        values: ['2', '12 L', '2000 bph',],
        overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        id: 'pe4uni',
        category: 'blowingMachine',
        name: 'PE-4 UNI',
        image: 'PE-4-UNI.jpg',
        values: ['4', '2.5 L', '6400',],
        overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        id: 'pe6uni',
        category: 'blowingMachine',
        name: 'PE-6 UNI',
        image: 'dummy_300x150.png',
        values: ['6', '2.5 L', '9200 bph',],
        overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
      {
        id: 'pe12uni',
        category: 'blowingMachine',
        name: 'PE-12 UNI',
        image: 'PE-12-UNI.jpg',
        values: ['12', '0.06 L', '12000',],
        overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
      },
    ],
    locale: {
      current: {},
      hu: {
        //REGULAR EXPRESSIONS
        //#region
        re1: 'Érdeklődöm',
        ty:'Köszönjük!',
        //#endregion
        //SITES
        //#region
        sites: [
          {text: 'Főoldal', url: 'home'},
          {text: 'Flakongyártás', url: 'flakongyartas'},
          {text: 'Kanálgyártás', url: 'bucket'},
          {text: 'Flakonfúvó gépgyártás', url: 'flakonfuvogep'},

        ],
        //#endregion
        //MAIN VIEW
        //#region
        h1: 'Harminc év, ',
        h2: 'a tapasztalat',
        h3: ' garanciája',
        h4: 'Korszerű technológiai megoldásaink a vevőink kényelmét, produktivitását, valamint a költséghatékonyság növelését szolgálják hosszú éveken keresztül, versenyképes áron.',
        h5: 'Beüzemelt gép',
        h6: 'Év Tapasztalat',
        h7: 'Termékkategória',
        h8: 'Munkatárs',
        hb1: 'Flakonfúvó gépgyártás',
        hb2: 'Magyarország legnagyobb Flakonfúvó Gép gyártója',
        hb3: 'Flakongyártás',
        hb4: 'Az egyedi tervezésű PET flakonok szakértője',
        hb5: 'Szerelékgyártás',
        hb6: 'A professzionális kanalak nálunk készülnek',
        hb7: 'Olvass tovább →',
        h9: 'Kérjen ajánlatot!',
        au1: 'Rólunk',
        au2: 'iparágakon átívelő megoldások',
        au3: 'Átfogó szakmai tapasztalat a műanyag csomagolóipar, a gépgyártás, fémmegmunkálás, valamint a földmunkagép szerelékgyártás terén',
        au4: 'Fejlesztési és innovációs erőnkre, motivált munkatársaink teljesítményére, valamint elkötelezett és elismert szakértőink tudására támaszkodva fejlesztjük termékeinket, technológiánkat, annak érdekében, hogy hozzájárulhassunk vevőink fejlődéséhez és a közös sikerünkhöz.',
        at: 'Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló . Megbízható . Fenntartható . Időtálló .',
        h10: 'poliol szerelékgyártás',
        wfd1: 'Merjen velünk',
        rmb1: 'Több információ',
        rmb2: 'Érdeklődöm',
        wfd2: 'Széria és egyedi gyártás',
        wfd3: 'Azonnal elérhető katalógus termékek raktárról. Egyedi megoldások egyedi vevői igényekre, alacsony átfutási idővel.\'',
        wfd4: 'Poliol Service Assistance ™',
        wfd5: 'Folyamatos rendelkezésreállás, 24-48 órás kiszállás. Csere kanál, szerelék biztosítása a javítás időszakára.',
        wfd6: 'A részletekben is precíz',
        wfd7: 'Saját fejlesztésű és gyártású munkahenger, valamint gyorscserélő rendszerek.',
        wfd8: 'Részletek',
        h11: 'poliol flakonfúvó gépgyártás',
        bmwfd1: 'Gépgyártás',
        bmwfd2: 'vevőközpontú innováció',
        bmwfd3: 'A több mint 20 év tisztán magyar know-how tudásra és gyártási tapasztalatra épülő POLIOL flakonfúvó gépek tervezésnél és gyártásánál fő szempontunk, hogy olyan gépeket építsünk, amelyek időtálló értéket és stabil gyártó kapacitást biztosítanak a vevőinknek.',
        ml1: 'Helyszíneink',
        ml2: 'Helyszínen, a világ 8 országában POLIOL gyártmányú flakonfúvó gépek üzemelnek, maximális vevői elégedettség mellett.',
        ml3: 'Románia',
        ml4: 'Algéria',
        ml5: 'Magyarország',
        ml6: 'Belgium',
        ml7: 'Horvátország',
        ml8: 'Észak Macedónia',
        ml9: 'Moldávia',
        ml10: 'Szerbia',
        pmp1: 'Magyarország legnagyobb flakonfúvógép gyártója',
        pmp2: 'Megbízható működés',
        pmp3: 'A legmagasabb minőségű elektronikai és pneumatikai alkatrészek használatával',
        pmp4: 'Hidraulikus előforma felöntő',
        pmp5: 'a teljeskörű automatizáltság támogatására',
        pmp6: 'Erős váz',
        pmp7: 'Gépeink kiemelkedő stabilitása az erős váz felépítésével kezdődik',
        pmp8: 'Kedvező üzemeltetési költség',
        pmp9: 'Az alacsony szervizigénynek, valamint az energiahatékony megoldásoknak köszönhetően',
        h12: 'Különleges ajánlat! Szerezze be gépeinket most készletről!',
        h13: 'Miért válassza most?',
        h14: 'Azonnal elérhető: Nincs várakozási idő, rövid időn belül használatba veheti új gépét.',
        h15: 'Megbízhatóság és minőség: Gépeink évek óta bizonyítják kiválóságukat, legyen szó bármilyen iparágról.',
        h16: 'Kedvező árak: Különleges áron kínáljuk őket, így Ön is részesülhet a legjobb technológiában kedvező feltételekkel.',
        h17: 'Ne hagyja ki ezt a lehetőséget! Gépeink most még elérhetők készletről.',
        h18: 'poliol flakongyártás',
        bpm0: 'Flakongyártás',
        bpm1: '10 milliárd flakon',
        bpm2: 'tapasztalat',
        bpm3: 'Ötlettől a kész flakonig',
        bpm4: 'Egyedi design tervezés - 3D modellezés - gyártás - cimkézés, szitázás házon belül.',
        bpm5: 'Részletek',
        bpm6: 'Válasszon online',
        bpm7: 'Webshop termékek azonnali elérhetőséggel. Egyedi megoldások egyedi vevői igényekre, alacsony átfutási idővel.',
        bpm8: 'Flakon Webshop',
        bpm9: 'minden méretben',
        bpm10: 'Az igény alapján tervezünk és gyártunk különböző méretű, formájú - 40 ml térfogatú flakonoktól a 12 l térfogatú flakonokig, hengeres vagy négyzet alakú, de akár egyedi formavilággal bíró termékeket',
        bpm11: 'Részletek',
        cup1: 'kapcsolat',
        cup2: 'Keressen minket!',
        cup3: 'Forduljon hozzánk bizalommal',
        cup4: 'üzenet',
        cup5: 'írjon nekünk:',
        cup6: 'Név*',
        cup7: 'Telefon*',
        cup8: 'Üzenet (opcionális)',
        cup9: 'Üzenet küldése',
        cup10: 'Nem adott meg nevet',
        cup11: 'Nem adott meg e-mail címet',
        cup12: 'Nem adott meg telefonszámot',
        fc1: 'Segítünk a szintlépésben',
        fc2: 'Szerelék-, kanálgyártás',
        fc3: 'Tősi János',
        fc4: 'Flakonfúvó gépgyártás',
        fc5: 'Lukács Csaba',
        fc6: 'Flakongyártás',
        fc7: 'Bíró Adrienn',
        fc8: 'központ',
        fc9: 'Minden jog fenntartva © Poliol Kft. - 2024',
        fc10: 'cím',
        //#endregion

        //BOTTLE BLOWING SITE
        //#region
        //BOTTLE BLOWING REGULAR
        bb1: 'Iparágak ahol flakonjaink már teljesítettek',
        //BOTTLE BLOWING BANNER
        bbb1: 'HOTFILL technológia',
        bbb2: 'Tartsa meg versenyelőnyét forradalmi HOTFILL technológiánkkal. Maximális hőállóság és biztonság. Tudjon meg többet a legmodernebb palackgyártási módszereinkről!',
        bbb3: 'Tartsa meg versenyelőnyét forradalmi HOTFILL technológiánkkal. Maximális hőállóság és biztonság.',
        bbb4: 'Környezettudatosság',
        bbb5: 'Fenntartható PET palackgyártás környezetbarát megoldásokkal. Fedezze fel, hogyan óvjuk a bolygót termékeinkkel!',
        bbb6: 'Egyedi tervezés',
        bbb7: 'Ötlettől a kész flakonig, a teljes palackgyártási folyamatot házon belül végezzük. Az egyedi tervezésű koncepcióktól a termék előállításáig csapatunk mindent egy helyen kezel. Hatékonyan és eredményesen alakítjuk át terveit kiváló minőségű PET palackokká.',
        bbb8: 'Ötlettől a kész flakonig, a teljes palackgyártási folyamatot házon belül végezzük. Az egyedi tervezésű koncepcióktól a termék előállításáig csapatunk mindent egy helyen kezel.',
        bbb9: 'Címkézés, szitázás',
        bbb10: 'Növelje a PET palackok vonzerejét címkézési és szitázási szolgáltatásainkkal! Kiváló minőségű, egyedileg testreszabott lehetőségek, melyek segítségével termékei kitűnnek majd a polcokon!',
        bbb11: 'Növelje a PET palackok vonzerejét címkézési és szitázási szolgáltatásainkkal!',
        //BOTLLE BLOWING PRODUCT CATEGORIES
        bbpc1: 'Vegyipar',
        bbpc2: 'Tartós és biztonságos PET palackok a vegyszerek és tisztítószerek tárolására.',
        bbpc3: 'Élelmiszeripar',
        bbpc4: 'Higiénikus és biztonságos PET palackok, melyek megőrzik az élelmiszerek frissességét és minőségét.',
        bbpc5: 'Ásványi olaj, kenőanyag',
        bbpc6: 'Ellenálló PET palackok, melyek biztonságosan tárolják és szállítják az ásványi olajokat és kenőanyagokat.',
        bbpc7: 'Kozmetikai',
        bbpc8: 'Elegáns és tartós PET palackok, melyek megőrzik a kozmetikai termékek frissességét és minőségét.',
        //BOTTLE BLOWING TITLE TEXT IMAGE
        bbtti1: 'miért a POLIOL flakonok',
        bbtti2: 'ÖN ELKÉPZELI, MI MEGVALÓSÍTJUK',
        bbtti3: 'A kiváló minőségben gyártott, saját tervezésű és fejlesztésű műanyag alapanyagú flakonjaink rendszeresen bizonyítottak az elmúlt években.',
        bbtti4: 'Számtalan kis-, és középvállalat egyedi igénye alapján tervezünk és gyártunk különböző méretű, formájú - 40 ml térfogatú flakonoktól a 12 l térfogatú flakonokig, hengeres vagy négyzet alakú, de akár egyedi formavilággal bíró - flakonokat, amelyek főként különböző folyadékok – ital, élelmiszer és vegyianyag - tárolására alkalmasak. Egyik legnagyobb sikerünk, hogy a saját tapasztalatunk és tudásunk alapján megtervezett és mai használatban lévő Szentkirályi Ásványvíz 5 literes flakonunk NÍVÓDÍJAT kapott.',
        //BOTTLE BLOWING PRODUCT HEROES
        bbpc11: 'HOTFILL',
        bbpc12: 'Frissesség minden cseppben',
        bbpc13: 'Élvezze új Hotfill termékeink előnyeit, melyek lehetővé teszik a magas hőmérsékletű folyadék töltést a friss ízek megőrzéséért és a hosszabb eltarthatóságért. Tapasztalja meg, hogyan emeljük új szintre a PET csomagolástechnikát. Legyen részese a Hotfill forradalmának!',
        bbpc14: ['Gyümölcslé', 'Tea', 'Energiaital', 'Méz', 'Smoothie'],
        bbpc21: 'Élelmiszer',
        bbpc22: 'A higiénikus tárolás szolgálatában 30 éve',
        bbpc23: 'Higiénikus és biztonságos PET palackok, melyek megőrzik az élelmiszerek frissességét és minőségét. Fedezze fel megoldásainkat a biztonságos tárolás érdekében!',
        bbpc24: ['Víz', 'Étolaj', 'Tej', 'Méz', 'Szörp'],
        bbpc31: 'Szeszipar',
        bbpc32: 'Minőség a palackban is',
        bbpc33: 'Kiváló minőségű PET palackok a szeszes italokhoz, melyek megőrzik az ízeket és frissességet. Válassza a legjobb megoldást, hogy prémium minőségben szolgálhassa ki ügyfeleit!',
        bbpc34: ['Sör', 'Bor', 'Pálinka', 'Cider'],
        bbpc41: 'Kozmetika',
        bbpc42: 'Elegancia és tartósság egy palackban',
        bbpc43: 'Elegáns és tartós PET palackok, melyek megőrzik a kozmetikai termékek frissességét és minőségét. Tegye vonzóvá márkáját kiváló csomagolási megoldásainkkal!',
        bbpc44: ['Krémek', 'Testápolók','Tusfürdők', 'Samponok'],
        bbpc51: 'Ásványi olaj, kenőanyag',
        bbpc52: 'Biztonságos tárolás és szállítás',
        bbpc53: 'Ellenálló PET palackok, melyek biztonságosan tárolják és szállítják az ásványi olajokat és kenőanyagokat. Bízza ránk a csomagolást, hogy termékei minden körülmények között tökéletes állapotban maradjanak!',
        bbpc54: ['Motorolaj', 'Fékfolyadék', 'Kenőanyagok', 'Hűtőfolyadék'],
        bbpc61: 'Vegyszer, tisztítószer',
        bbpc62: 'Biztonság és megbízhatóság',
        bbpc63: 'Tartós és biztonságos PET palackok a vegyszerek és tisztítószerek tárolására. Válassza a megbízhatóságot és a minőséget termékeinkkel!',
        bbpc64: ['Tisztítószerek', 'Fertőtlenítők', 'Mosószerek', 'Ipari vegyszerek'],

        //#endregion
        //BUCKET PRODUCTION SITE
        //#region
        //BUCKET PRODUCTION REGULAR
        bp1: 'Szerelékeink',
        //BUCKET PRODUCTION BANNER
        bpb1: 'Nagy teherbírású bányakanál',
        bpb2: 'Innovatív megoldások, kivételes szilárdságú és tartósságú bányakanalak!',
        bpb3: '7 m³ magasürítésű kanál',
        bpb4: 'Egyedi megoldásokat tervezünk és gyártunk, mindig az ügyfelek specifikus igényei szerint!',
        bpb5: 'Terméklap',
        //BUCKET PRODUCTION CATEGORIES
        bpc1: 'Faipar, Mezőgazdaság',
        bpc2: 'Tartós és hatékony kanalak és szerelékek a faipar és mezőgazdaság számára. 30 év tapasztalat, nagy teljesítményű tervező és gyártó sor!',
        bpc3: 'Nagy tömegű földmunka',
        bpc4: 'Erős kanalak és szerelékek a nagy tömegű földmunkákhoz. Növelje hatékonyságát és minimalizálja a leállási időt!',
        bpc5: 'Közműépítés',
        bpc6: 'Megbízható eszközök a közműépítéshez. Rendeljen még ma, és használja ki gyors kiszállási szolgáltatásunkat!',
        bpc7: 'Városfenntartás',
        bpc8: 'Professzionális kanalak és szerelékek a hatékony városfenntartásért. Megbízhatóságot tervezünk, gyártunk és kínálunk!',
        bpc9: 'Bányászat',
        bpc10: 'Tartós Hardox anyagból készült eszközök a kő- és sziklabányászat igényeihez. Fedezze fel termékeinket, melyek ellenállnak még a legkeményebb körülményeknek is!',
        bpc11: 'Gyors szerelékváltók',
        bpc12: 'Gyors és megbízható szerelékváltók a maximális hatékonyságért. Válasszon innovatív megoldásaink közül, vagy tervezzünk teljesen egyedit együtt!',
        //BUCKET PRODUCTION TITLE TEXT IMAGE
        bptti1: 'miért POLIOL szerelék',
        bptti2: 'Merjen velünk!',
        bptti3: 'A Poliol több mint 30 éves tapasztalattal rendelkezik a kanalak és szerelékek gyártásában. Cégünk innovatív megoldásokat kínál, amelyek garantálják a tartósságot és a megbízhatóságot az ipar minden területén.',
        bptti4: 'Saját fejlesztésű és gyártású munkahengereket és gyorscserélő rendszereket kínálunk. Ezek az innovációk megbízhatóságot és hatékonyságot biztosítanak a legkülönfélébb alkalmazásokhoz. Csapatunk professzionális eszköztárral és tudással rendelkezik a kanalak és szerelékek felújításában és modernizálásában is, ezzel biztosítva a legkülönbözőbb iparágakban is a berendezések tartósságát és hatékonyságát. Termékeink Hardox anyagokból készülnek, melyek kivételes szilárdságukról és tartósságukról ismertek, biztosítva a hosszú távú teljesítményt. De nemcsak a megbízhatóság jellemző ránk, hanem a gyorsaság is: katalógus termékeinket, akár 2 hetes átfutási idővel szállítjuk!',
        //#endregion
        //BOTTLE BLOWING MACHINE PRODUCTION
        //#region
        //BOTTLE BLOWING MACHINE PRODUCTION REGULAR
        bbmp1: 'Flakonfúvógép típusaink',
        bbmp2: 'Flakonfúvó gépeink',
        bbmp3: 'További gépeink',
        bbmp4: 'Termékek elrejtése',
        bbmProdProperties: ['Fészekszám', 'Maximális térfogat', 'Maximális gyártási kapacitás'],
        //BOTTLE BLOWING MACHINE PRODUCTION BANNER
        bbmpb1: 'PA-2 ECO+',
        bbmpb2: 'A félautomata gépek kiváltásának leghatékonyabb eszköze.',
        bbmpb3: 'PE-UNI',
        bbmpb4: '30 év fejlesztés és megbízhatóság új köntösben.',
        //BOTTLE BLOWING MACHINE PRODUCTION CATEGORIES
        bbmpc1: 'Tejüzemek',
        bbmpc2: 'Gépeinkkel prémium minőségű PET palackokat gyárthat, melyek biztosítják a tejtermékek biztonságos és hosszú távú tárolását.',
        bbmpc3: 'Sörfőzdék, borászatok',
        bbmpc4: 'Innováció mindfelett: gyártson innovatív, trendi és fancy palackokat házon belül! Termékminőség és tartósság!',
        bbmpc5: 'Üdítőgyártás',
        bbmpc6: 'Tartós és esztétikus PET palackokat gyárthat üdítőgyártóként. Biztosítsa gépeinkkel a termékek vonzerejét és biztonságát, házon belül!',
        bbmpc7: 'Kozmetikai cégek',
        bbmpc8: 'Növelje márkája vonzerejét tartós és elegáns PET palackokkal, melyek biztosítják a termékek biztonságát és frissességét. Gyártson PET palackokat házon belül gépeinkkel!',
        bbmpc9: 'Olajüzemek',
        bbmpc10: 'Ellenálló, minőségi palackok a legszigorúbb ipari követelményeknek megfelelően. Biztosítsa a termékek épségét, házon belül!',
        //BOTTLE BLOWING MACHINE PRODUCTION TITLE TEXT IMAGE
        bbmptti1: 'miért a Poliol?',
        bbmptti2: 'A legtestreszabhatóbb flakonfúvógépek a világon',
        bbmptti3: 'A Poliol flakonfúvógépei a testreszabhatóság és megbízhatóság csúcsát képviselik. Több mint 30 év tervezési és gyártási tapasztalatával biztosítjuk, hogy gépeink megfeleljenek a legszigorúbb ipari követelményeknek.',
        bbmptti4: 'Innovatív megoldásaink révén gépeink nemcsak energiatakarékosak és helytakarékosak, hanem gyors átállási lehetőségeket is kínálnak, maximalizálva az üzemeltetési hatékonyságot. A szervómotoros működtetés és hidraulikus előforma feladó rendszer garantálja a precíz és megbízható gyártást. Már több mint három évtizede specializálódtunk a lineáris palackfúvó gépekre, páratlan szakértelmet és megbízhatóságot kínálva a piacon. Gépeinket stabil, masszív kialakítás, energiahatékonyság és helytakarékosság jellemzi, biztosítva, hogy zökkenőmentesen illeszkedjenek gyártási környezetébe. Válassza a Poliol flakonfúvógépeit, és élvezze a teljes mértékben testreszabható és kiváló minőségű megoldásokat!',
        //BOTTLE BLOWING MACHINE PRODUCTION PRODUCT HEROES
        bbmppc11: '30 éves minőség, új köntösben.',
        bbmppc12: 'Az univerzálisabb felhasználást nyújtó UNI gépcsalád a teljesen automata működésével emelkedik ki a terméklistából. Gyors átállást, erősebb fúvónyomást és nagyobb űrtartalmú flakonokat garantál vásárlóinknak.',
        bbmppc13: 'POLIOL Finance™   Bérleti lehetőségek: Rugalmas bérleti lehetőségek az Ön pénzügyi igényeinek megfelelően.',
        bbmppc14: ['0.2 l - 19 l gyártható űrtartalom', 'szerszám nélküli átállás 10 perc alatt', 'HOTFILL opció', 'Akár 40 Bar fúvónyomás a legnagyobb kihívásokra'],
        bbmppc21: 'A félautomata gépek kiváltásának leghatékonyabb eszköze.',
        bbmppc22: 'Az economy sorozatú flakonfúvó gépeink tervezésénél elsősorban az energiatakarékosság, valamint a fenntarthatóság voltak a legfőbb szempontok, a gyártásunkra jellemző maximális minőségi szint és megbízható működés megtartása mellett.',
        bbmppc23: 'POLIOL Finance™   Bérleti lehetőségek: Rugalmas bérleti lehetőségek az Ön pénzügyi igényeinek megfelelően.',
        bbmppc24: ['Teljesen automata működés', 'Alacsony energiafogyasztás', 'Levegővisszanyerő rendszer', 'Kompakt kivitel', 'Gyors, könnyű telepítés'],
        bph: ' db/óra',
        //#endregion

        //PRODUCT PAGE
        //#region
        pp1: 'Tulajdonságok',
        pp2: 'Ajánlatkérés',
        pp3: 'Javasolt felhasználás',
        pp4: 'Galéria',
        //#endregion

        //PRODUCT GROUPS
        //#region
        prodGroupLocale:
            [
              {
                id: 'buckets',
                name: 'Szerelékek',
                types: [
                  {
                    id: 'faipar',
                    name: 'Faipar, Mezőgazdaság',
                    subtitle: 'Tartós és hatékony kanalak és szerelékek a faipar és mezőgazdaság számára. 30 év tapasztalat, nagy teljesítményű tervező és gyártó sor!',
                  },
                  {
                    id: 'foldmunka',
                    name: 'Nagy tömegű földmunka',
                    subtitle: 'Erős kanalak és szerelékek a nagy tömegű földmunkákhoz. Növelje hatékonyságát és minimalizálja a leállási időt!',
                  },
                  {
                    id: 'kozmu',
                    name: 'Közműépítés',
                    subtitle: 'Megbízható eszközök a közműépítéshez. Rendeljen még ma, és használja ki gyors kiszállási szolgáltatásunkat!',
                  },
                  {
                    id: 'varos',
                    name: 'Városfenntartás',
                    subtitle: 'Professzionális kanalak és szerelékek a hatékony városfenntartásért. Megbízhatóságot tervezünk, gyártunk és kínálunk!',
                  },
                  {
                    id: 'banyaszat',
                    name: 'Bányászat',
                    subtitle: 'Tartós Hardox anyagból készült eszközök a kő- és sziklabányászat igényeihez. Fedezze fel termékeinket, melyek ellenállnak még a legkeményebb körülményeknek is!',
                  },
                  {
                    id: 'gyorscserelok',
                    name: 'Gyors szerelékváltók',
                    subtitle: 'Gyors és megbízható szerelékváltók a maximális hatékonyságért. Válasszon innovatív megoldásaink közül, vagy tervezzünk teljesen egyedit együtt!',
                  },
                ]
              },
              {
                id: 'bottles',
                name: 'Flakonok',
                types: [
                  {
                    id: 'vegyipar',
                    image: 'vegyipar.png',
                    name: 'Vegyipar',
                    subtitle: 'Tartós és biztonságos PET palackok a vegyszerek és tisztítószerek tárolására.',
                  },
                  {
                    id: 'elelmiszeripar',
                    image: 'Uditogyartas.png',
                    name: 'Élelmiszeripar',
                    subtitle: 'Higiénikus és biztonságos PET palackok, melyek megőrzik az élelmiszerek frissességét és minőségét.',
                  },
                  {
                    id: 'asvanyi-olaj-kenoanyag',
                    image: 'Olajuzemek.png',
                    name: 'Ásványi olaj, kenőanyag',
                    subtitle: 'Ellenálló PET palackok, melyek biztonságosan tárolják és szállítják az ásványi olajokat és kenőanyagokat.',
                  },
                  {
                    id: 'kozmetikai',
                    image: 'Kozmetikai-cegek.png',
                    name: 'Kozmetikai',
                    subtitle: 'Elegáns és tartós PET palackok, melyek megőrzik a kozmetikai termékek frissességét és minőségét.',
                  },
                ]
              },
            ],
        //#endregion


        //PRODUCTS
        //#region
        prodLocale: [
          {
            id: 'rezsu-kanal',
            name: 'Rézsűkanál',
            properties: [
              'HARDOX 500-as vágóél',
              'Erős, egyedi kivitelű kanálprofil',
              'Többféle profillal is elérhető',
            ],
            usage: 'Rézsű szedésre medrek árkok tisztítására, tükör szedésre ajánljuk.',
            text: 'A fix rézsűkanál tökéletes megoldás a földmunkákhoz, különösen árkok, lejtők és rézsűk kialakításához. Az eszköz magas minőségű és kiemelkedő kopásállóságú, hosszú élettartamot biztosítva. Segítségével precízebb és hatékonyabb munkavégzés érhető el. Válassza a fix rézsűkanalat a pontos és megbízható földmunkákért!',
          },
          {
            id: 'hidraulikus-rezsu-kanal',
            name: 'Hidraulikusan billenthető rézsű kanál',
            properties: [
              'HARDOX 500-as vágóél és oldalél',
              'Masszív, S355-ös acél kanáltest',
              'Több merevítő sarló',
              'Modern kanálprofil',
              'Hátrafelé túlnyúló símító felület többletköltség nélkül',
              'Precíz munkavégzés állítható átfolyási sebeséggel a munkahengerek védelme és a precíz munkavégzés érdekében'
            ],
            usage: 'Minden olyan tükör és rézsű szedési feladathoz ajánjuk ahol a talaj egyenletlenség kiküszöbölése nem megoldható a gép számára.',
            text: 'A hidraulikus rézsűkanál forradalmasítja a rézsűk és árkok formálását, lehetővé téve a precíz és hatékony munkavégzést bármilyen munkaterületen. Az innovatív hidraulikus rendszer lehetővé teszi a könnyű és pontos manőverezést, valamint a magas teljesítményű földmunkákat a gépvezető számára. A magas minőségű anyagokból készült kanál kiemelkedő kopásállóságot és hosszú élettartamot garantál a hosszú távú, megbízható használat érdekében.\n' +
                'Válassza a hidraulikus kivitelt a széleskörű alkalmazhatóság és a hatékony földmunkák rugalmasságáért! Bízza rá a legprecízebb munkafolyamatokat egy olyan eszközre, amely a teljesítmény és a megbízhatóság csúcsát képviseli minden munkaterületen!'
          },
          {
            id: 'melyaso-kanal-korom-nelkul',
            name: 'Mélyásó kanál vágóéllel',
            properties: [
              'Gépkezelői visszajelzések alapján kialakított kanálprofil',
              'HARDOX 500-as vágóél és oldalél',
              'Standard, erősített, és extra erősített kivitelben is',
              'Minden típusú kotróra'
            ],
            usage: 'A legtöbb földszint alatti ásáshoz, rakodáshoz ajánljuk.',
            text: 'A mélyásó kanál ideális mélyásásokhoz, például alapok és árkok létrehozásához. Az egyedi igények szerint gyártott eszköz magas minőségű, megbízható és tartós. Kiváló választás, amellyel pontos és hatékony földmunkákat végezhet. Válassza a mélyásó kanalat a professzionális munkavégzésért és a kiváló eredményekért!'
          },
          {
            id: 'rezsu-kanal-varos',
            name: 'Rézsűkanál',
            properties: [
              'HARDOX 500-as vágóél',
              'Erős, egyedi kivitelű kanálprofil',
              'Többféle profillal is elérhető',
            ],
            usage: 'Rézsű szedésre medrek árkok tisztítására, tükör szedésre ajánljuk.',
            text: 'A fix rézsűkanál tökéletes megoldás a földmunkákhoz, különösen árkok, lejtők és rézsűk kialakításához. Az eszköz magas minőségű és kiemelkedő kopásállóságú, hosszú élettartamot biztosítva. Segítségével precízebb és hatékonyabb munkavégzés érhető el. Válassza a fix rézsűkanalat a pontos és megbízható földmunkákért!',
          },
          {
            id: 'melyaso-kanal',
            name: 'Mélyásó kanál körömmel',
            properties: [
              'Gépkezelői visszajelzések alapján kialakított kanálprofil',
              'HARDOX 500-as vágóél és oldalél',
              'Választható körömrendszerrel',
              'Kopóccsíkokkal szerelve a palásst lemezen',
              'Standard, erősített, és extra erősített kivitelben is',
              'Minden típusú kotróra'
            ],
            usage: 'A legtöbb földszint alatti ásáshoz, rakodáshoz ajánljuk.',
            text: 'A mélyásó kanál ideális mélyásásokhoz, például alapok és árkok létrehozásához. Az egyedi igények szerint gyártott eszköz magas minőségű, megbízható és tartós. Kiváló választás, amellyel pontos és hatékony földmunkákat végezhet. Válassza a mélyásó kanalat a professzionális munkavégzésért és a kiváló eredményekért!'
          },
          {
            id: 'trapez-kanal-foldmunka',
            name: 'Trapéz kanál',
            properties: [
              '500 HB vágóél',
              'Hidraulikusan dönthető és állítható oldaléllel is rendelhető',
              'Gépkezelői visszajelzések alapján kialakított profil',
            ],
            usage: 'Árkok ásásához ajánljuk - utómunkát nem igénylő szimmetrikus és aszimmetrikus árokprofilokhoz',
            text: 'Fedezze fel a trapéz kanál sokoldalúságát, amely ideális megoldást kínál árkok ásásához, alakításához és tisztántartásához, valamint csatornák karbantartásához! Magas minőségű anyagból készült és kiemelkedő kopásállósággal rendelkezik, garantálva a hosszú élettartamot és a megbízhatóságot a munkafolyamat során. Emelje munkái színvonalát a trapéz kanál segítségével, amely precízebb és hatékonyabb eredmények elérését teszi lehetővé minden körülmények közt. A legjobb választás, amikor a minőség és a teljesítmény számít!'
          },
          {
            id: 'melyaso-kanal-korom-nelkul-kozmu',
            name: 'Mélyásó kanál vágóéllel',
            properties: [
              'Gépkezelői visszajelzések alapján kialakított kanálprofil',
              'HARDOX 500-as vágóél és oldalél',
              'Standard, erősített, és extra erősített kivitelben is',
              'Minden típusú kotróra'
            ],
            usage: 'A legtöbb földszint alatti ásáshoz, rakodáshoz ajánljuk.',
            text: 'A mélyásó kanál ideális mélyásásokhoz, például alapok és árkok létrehozásához. Az egyedi igények szerint gyártott eszköz magas minőségű, megbízható és tartós. Kiváló választás, amellyel pontos és hatékony földmunkákat végezhet. Válassza a mélyásó kanalat a professzionális munkavégzésért és a kiváló eredményekért!'
          },
          {
            id: 'trapez-kanal-kozmuepites',
            name: 'Trapéz kanál',
            properties: [
              '500 HB vágóél',
              'Hidraulikusan dönthető és állítható oldaléllel is rendelhető',
              'Gépkezelői visszajelzések alapján kialakított profil',
            ],
            usage: 'Árkok ásásához ajánljuk - utómunkát nem igénylő szimmetrikus és aszimmetrikus árokprofilokhoz',
            text: 'Fedezze fel a trapéz kanál sokoldalúságát, amely ideális megoldást kínál árkok ásásához, alakításához és tisztántartásához, valamint csatornák karbantartásához! Magas minőségű anyagból készült és kiemelkedő kopásállósággal rendelkezik, garantálva a hosszú élettartamot és a megbízhatóságot a munkafolyamat során. Emelje munkái színvonalát a trapéz kanál segítségével, amely precízebb és hatékonyabb eredmények elérését teszi lehetővé minden körülmények közt. A legjobb választás, amikor a minőség és a teljesítmény számít!'
          },
          {
            id: 'ripper',
            name: 'Bontófog',
            properties: [
              'Alaptest S355 szerkezeti acélból',
              'HARDOX 500-as burkolat',
              'Könnyen cserélhető körömrendszer',
              'Folyamatosan hegyesen kopó kialakítás',
              'Bármely felfogatással kompatibilis'
            ],
            usage: 'Kemény, szikes talajok fellazításához, tuskózáshoz földbe ágyazódott anyagok kiforgatásához.',
            text: 'Fedezze fel a szakítóköröm sokoldalúságát, ami nemcsak a mini gépeknek, de a legnagyobb lánctalpas kotróknak is fontos eszköze! Ez az innovatív eszköz lehetővé teszi a kemény, szikes talaj felázítását, hogy azt követően könnyedén és hatékonyan vehesse fel a rakodandó anyagokat. Vessen be egy megoldást, amely garantálja a hatékonyságot és eredményességet minden munkaterületen!'
          },
          {
            id: 'hidraulikus-rezsu-kanal-kozmu',
            name: 'Hidraulikusan billenthető rézsű kanál',
            properties: [
              'HARDOX 500-as vágóél és oldalél',
              'Masszív, S355-ös acél kanáltest',
              'Több merevítő sarló',
              'Modern kanálprofil',
              'Hátrafelé túlnyúló símító felület többletköltség nélkül',
              'Precíz munkavégzés állítható átfolyási sebeséggel a munkahengerek védelme és a precíz munkavégzés érdekében'
            ],
            usage: 'Minden olyan tükör és rézsű szedési feladathoz ajánjuk ahol a talaj egyenletlenség kiküszöbölése nem megoldható a gép számára.',
            text: 'A hidraulikus rézsűkanál forradalmasítja a rézsűk és árkok formálását, lehetővé téve a precíz és hatékony munkavégzést bármilyen munkaterületen. Az innovatív hidraulikus rendszer lehetővé teszi a könnyű és pontos manőverezést, valamint a magas teljesítményű földmunkákat a gépvezető számára. A magas minőségű anyagokból készült kanál kiemelkedő kopásállóságot és hosszú élettartamot garantál a hosszú távú, megbízható használat érdekében.\n' +
                'Válassza a hidraulikus kivitelt a széleskörű alkalmazhatóság és a hatékony földmunkák rugalmasságáért! Bízza rá a legprecízebb munkafolyamatokat egy olyan eszközre, amely a teljesítmény és a megbízhatóság csúcsát képviseli minden munkaterületen!'
          },
          {
            id: 'erositett-melyaso-kanal',
            name: 'Bányakanál',
            properties: [
              'Hardox HiTuf 500 kanáltest',
              'Kopásálló speciálís hozaganyaggal készült varratok',
              'A legkeményebb bányászati feladatokra',
              'Egyedi felszereltségi szinttel'
            ],
            usage: 'A legkeményebb bányászati feladatokra és igénybevételekre ajánljuk. Hosszútávú megoldás elérhető áron helyben az országból.',
            text: 'A bányakanál kifejezetten bányászati és nehéz ipari környezetben való felhasználásra készült, nagy mennyiségű anyagok mozgatására. Az eszköz magas minőségű és kiemelkedő kopásállóságú, hosszú élettartamot biztosítva. Hatékonyságával segíti a komolyabb anyagmozgatási feladatok elvégzését. Válassza bányakanalunkat a megbízható és tartós anyagmozgatásért!'
          },
          {
            id: 'erositett-rakodo-kanal',
            name: 'Erősített rakodókanál (HDX)',
            properties: [
              'A legnehezebb bányászati rakodó munkálatokra tervezve',
              'Egyedi méretben minden géptípusra',
              'A kanál minden alkatrésze kopóelemekk és szegmensekkel felszerelhető',
              'Tetszőleges kanálprofil és vágóél forma kiválasztással'
            ],
            usage: 'Bánya kivitel kifejezetten bányászati rakodási feladatokra. Egyedi felszereltséggel amit az ügyfél igényeire szabunk.',
            text: 'Fedezze fel a teljesítmény és a tartósság csúcspontját az erősített rakodókanállal, amelyet kifejezetten a legkeményebb bányászati és ipari kihívásokra terveztünk. A robusztus kialakítás és a túlméretezett alkatrészek még a legszélsőségesebb munkakörülmények között is kiemelkedő teljesítményt nyújtanak. A kanál kopásnak kitett részeit különleges kopásálló lemezekkel védjük, míg az extra megerősített oldalél garantálja a maximális védelmet és tartósságot.'
          },
          {
            id: 'rakodo-kanal',
            name: 'Rakodókanál',
            properties: [
              'Minden típusú rakodógéphez',
              'Akár HARDOX 500 as kanáltesstel',
              'Választható körömszettel mindenféle méretben',
              'Nyitható és magasürítésű kivitelben is',
              'Standard, erősített és bánya kivitelben gyártjuk'
            ],
            usage: 'Elsősorban rakodási feladatokra ajánljuk a kicsitől a nagy tömegig. Minden géptípusra. Anyag milyenségétől függően vágóéles, vagy körmös felszereltséggel.',
            text: 'A rakodókanál sokoldalúan használható anyagok, például homok, kavics és föld rakodására és szállítására. Az egyedi gyártásnak köszönhetően pontosan az Ön igényeire szabott, magas minőségű eszköz. Megbízhatóságával és tartósságával növeli a munkavégzés hatékonyságát. Válassza rakodókanalunkat minden rakodási feladathoz!'
          },
          {
            id: 'ripper-banyaszat',
            name: 'Bontófog',
            properties: [
              'Alaptest S355 szerkezeti acélból',
              'HARDOX 500-as burkolat',
              'Könnyen cserélhető körömrendszer',
              'Folyamatosan hegyesen kopó kialakítás',
              'Bármely felfogatással kompatibilis'
            ],
            usage: 'Kemény, szikes talajok fellazításához, tuskózáshoz földbe ágyazódott anyagok kiforgatásához.',
            text: 'Fedezze fel a szakítóköröm sokoldalúságát, ami nemcsak a mini gépeknek, de a legnagyobb lánctalpas kotróknak is fontos eszköze! Ez az innovatív eszköz lehetővé teszi a kemény, szikes talaj felázítását, hogy azt követően könnyedén és hatékonyan vehesse fel a rakodandó anyagokat. Vessen be egy megoldást, amely garantálja a hatékonyságot és eredményességet minden munkaterületen!'
          },
          {
            id: 'hidraulikus-kotro-XCMG-gyorscserelo',
            name: 'Hidraulikus / Mechanikus Kotró gyorscserélő',
            properties: [
              'A kotrógép sokoldaluságának kulcsfontosságú eszköze. Idő emberi és élő erő megtakarítása hidraulikus és mechanikus változatban minden géptípusra ',
              'A kotrógép sokoldaluságának kulcsfontosságú eszköze',
              'Idő, emberi és gépi erő megtakarítására',
              'Hidraulikus és mechanikus változatban is!',
              'Minden géptípusra'
            ],
            usage: 'Több felhasználási célra alkalmazott kotrógépekre ajánljuk, sűrű eszközcsere esetén. A mai fejlődő világban szinte alapfelszerelés egy kotróhoz.',
            text: 'A hidraulikus kotró gyorscserélő lehetővé teszi a kotrószerszámok gyors cseréjét, ami jelentősen növeli a gép sokoldalúságát. Az egyedi igények szerint gyártott eszköz magas minőségű és tartós. Segítségével gyorsabban végezheti el a különböző földmunkákat, mindezt a legmagasabb szintű hatékonysággal. Válassza a hidraulikus kotró gyorscserélőt a rugalmas és gyors munkavégzésért!'
          },
          {
            id: 'hidraulikus-rakodo-VOLVO-gyorscserelo',
            name: 'Hidraulikus Rakodó gyorscserélő',
            properties: [
              'A rakodógép többcélú felhasználását teszi lehetővé.',
            ],
            usage: 'Többféle felhasználású rakodógépek esetén ajánljuk, sűrű eszközcsere esetén.',
            text: 'Vízelvezető árkok, csatornák, rézsük kialakítására fejlesztett eszközünk.'
          },
          {
            id: 'balavilla',
            name: 'Bálavilla',
            properties: [
              'Saját profilú szerelék',
              'Egyszerű kialakítás és költséghatékony árképzés',
              'Cserélhető tüskékkel',
              'Bármilyen felfogatásra'
            ],
            usage: 'Mezőgazdasági iparágnak ajánljuk bála rakodásra.',
            text: 'A bálavilla kiválóan alkalmas szalmabálák, szénabálák és más mezőgazdasági anyagok mozgatására és rakodására. Az egyedi igények szerint gyártott eszköz megbízható és tartós megoldást kínál. Megkönnyíti és hatékonyabbá teszi a bálák kezelését mindennapi használat során.',
            noOfRefPics: 1
          },

          {
            id: 'tragyavilla',
            name: 'Trágyavilla',
            properties: [
              'Masszív, egyszerű test S355 szerkezeti acélból',
              'Cserélhető villasorral',
              'Hidraulikusan ráfogós krokodil kivitelben',
              'Egyszerű, alsó villasoros változatban is elérhető'
            ],
            usage: 'Mezőgazdasági munkálatokra, trágya rakodásra és szálas növényi hulladék rakodására ajánljuk.',
            text: 'A trágyavilla kiválóan alkalmas trágyakezelésre és -rakodásra a mezőgazdasági területeken. Az egyedi igények szerint gyártott eszköz magas minőségű, megbízható és tartós. Segítségével könnyedén és hatékonyan végezheti el a trágyamozgatási feladatokat. Legyen Ön is részese a hatékony mezőgazdasági gépesítés fejlődésének.'
          },
          {
            id: 'konnyuanyag-kanal',
            name: 'Könnyűanyag kanál',
            properties: [
              'Lefolyás gátló kanálprofil,Hardox 500 vágóél,nagy űrtartalom felesleges súly nélkül, csavarozható vágóél opcióval',
              'Lefolyás gátló kanálprofil',
              'HARDOX 500-as vágóél',
              'Nagy űrtartalom, felesleges súly nélkül',
              'Csavarozható vágóél opcióval'
            ],
            usage: 'Mezőgazdasági szóródó anyagok, szemes takarmány nagytömegű rakodására ajánljuk, fix és magasürítésű kivitelben egyaránt.',
            text: 'A könnyűanyag kanál ideális könnyű anyagok, például faforgács, komposzt és szemét rakodására és mozgatására. Az eszköz magas minőségű és kiemelkedő kopásállóságú, biztosítva a hosszú élettartamot. Tökéletes választás a hatékony és precíz munkavégzéshez. Válassza a könnyűanyag kanalat a hatékony anyagmozgatási feladatokhoz!'
          },
          {
            id: 'magasuritesu-kanal',
            name: 'Magasürítésű kanál',
            properties: [
              'Masszív kanáltest',
              'HARDOX 500-as vágóéllel',
              'Egyedi, lefolyás gátló kialakítással',
              'Valós köbméter számolással'
            ],
            usage: 'Magasban történő ürítési feladatokra ajánljuk.',
            text: 'A magasürítésű kanál a legnagyobb ipari feladatokra tervezett eszköz, amely lehetővé teszi a nagy mennyiségű anyag gyors és hatékony mozgatását. A robusztus kialakítás és az extra erősített szerkezet biztosítja a maximális teljesítményt még a legnehezebb terhelés esetén is. A magas minőségű anyagból készült kanál kiemelkedő kopásállóságot garantál, hosszú élettartamot biztosítva a folyamatos használat során.\n' +
                'Ne engedje, hogy a nagy feladatok megállítsák - válassza a magasürítésű kanalat a hatékonyság és megbízhatóság érdekében! Vezesse munkaterülete teljesítményét egy olyan eszközzel, amely biztosítja a legnagyobb termelékenységet és eredményességet minden körülmények között!'
          },
          {
            id: 'raklapvilla',
            name: 'Raklapvilla',
            properties: [
              'Széles teherbírási skála',
              'Minden géptípusra nem csak rakodógépra gyártjuk',
              'CE tanusítvány',
            ],
            usage: 'Raklapos árú mozgatására ajánljuk elsősorban. Valamint bármilyen egyéb tárgy mozgatására ami a teherbírásnak megfelel.',
            text: 'A raklapvilla ideális raklapok és különböző rakományok biztonságos és hatékony mozgatására. Az eszköz magas minőségű, megbízható és tartós. Az egyedi igények szerint készült szerelék segítségével egyszerűbben és precízebben végrehajthatók a rakodási feladatok. Válassza a raklapvillát a kiváló minőség és hatékonyság érdekében!'
          },


          //BLOWING MACHINES
          {
            id: 'pap1eco',
            category: 'blowingMachine',
            name: 'PAP-1 ECO',
            image: 'PAP-1-ECO.jpg',
            values: ['1', '0.75 L', '800'],
            overlayText: 'A PA gépcsaládba -a szervomotorral működtetett nyújtást kivéve- peumatikus munkahengerekkel működtetett gépek tartoznak. A PAP gépek kis hely és energia igénnyel kimondottan a kis űrtartalmú flakonok gyártására lettek kifejlesztetve.  Csak ez az ECO típus rendelhető  akár félautomata üzemeltetésű kivitelben is, kézi előforma adagolással és kézi flakon kivétellel. Egyszerű felépítésű gép.'
          },
          {
            id: 'pap1',
            category: 'blowingMachine',
            name: 'PAP-1',
            image: 'PAP-1.jpg',
            values: ['1', '0.75 L', '900'],
            overlayText: 'A PA gépcsaládba -a szervomotorral működtetett nyújtást kivéve- peumatikus munkahengerekkel működtetett gépek tartoznak. A PAP gépek kis hely és energia igénnyel kimondottan a kis űrtartalmú flakonok gyártására lettek kifejlesztve. Ez a géptípus teljesen automata kivitelben készül. A gép vezérlése alkalmas rá, hogy egy töltőgépet on-line módban kiszolgáljon, egy felszerelhető egységgel az elkészült flakonokat  szállítószalagra rendezve helyezi el. '
          },
          {
            id: 'pap1hotFill',
            category: 'blowingMachine',
            name: 'PAP-1 HOT FILL',
            image: 'PAP-1.jpg',
            values: ['1', '0.75 L', '650'],
            overlayText: 'A PAP-1 tíusú gép forrón tölthető flakonok előállítására alkalmas  kiegészítő csomaggal felszerelt gépe. A gép egyszeűen  átállítható a HOTFILL vagy a hagyományos  flakonok gyártására.'
          },
          {
            id: 'pa1eco',
            category: 'blowingMachine',
            name: 'PA-1 ECO',
            image: 'dummy_300x150.png',
            values: ['1', '2.25 L', '800'],
            overlayText: 'A PA gépcsaládba -a szervomotorral működtetett nyújtást kivéve- peumatikus munkahengerekkel működtetett gépek tartoznak. Ez a géptípus teljesen autpomata üzemmódú kivitelben készül. A gép vezérlése alaklmas rá, hogy egy töltőgépet on-line módban kiszolgáljon, egy  felszerelhető egységgel az elkészült flakonokat  szállítószalagra rendezve helyezi el. Egyszerű felépítésű gép.'
          },
          {
            id: 'pa1hotFill',
            category: 'blowingMachine',
            name: 'PA-1 HOT FILL',
            image: 'dummy_300x150.png',
            values: ['1', '2.25 L', '650'],
            overlayText: 'A PA-1 ECO tíusú gép forrón tölthető flakonok előállítására alkalmas  kiegészítő csomaggal felszerelt gépe. A gép egyszeűen  átállítható a HOTFILL vagy a hagyományos  flakonok gyárátsára.'
          },
          {
            id: 'pe15uni',
            category: 'blowingMachine',
            name: 'PE-15 UNI',
            image: 'PE-15-UNI.jpg',
            values: ['1', '6 L', '1300'],
            overlayText: 'A PE gépcsaládba tartozó gépek minden fő mozgása szervo motorral működtetett, így gyors mozgási  sebességeket érünk el, mely a ciklusidőt lerövíditi, nagyobb termelékenységet eredményezve. A  gépcsoport a legkorszerűbb elektronikai, adatátviteli elemekből épül fel, lehetővé téve a mozgások és a technológia nagyon precíz szabályozottt visszacsatolt beállítását. A PE gépcsládot a nagyfokú  villamos energiatakarékosság , valamint a különböző szintű sűrített levegő visszanyerő rendszereknek köszönhető minimalizált légfogyasztás jellemzi.  A töltőgépekkel történő egyszerű összeépítés segítésére a flakonok légpályán keresztül hagyják el a gépet, a vezérlés alpesetben is alkalmas az on-line töltőgépes együttműködésre. A gyors termék és előforma váltást sok alapesetben is beépített, valamint számos opciós megoldás segíti.'
          },
          {
            id: 'pe110uni',
            category: 'blowingMachine',
            name: 'PE-110 UNI',
            image: 'PE-110-UNI.jpg',
            values: ['1', '12 L', '1200',],
            overlayText: 'A PE gépcsaládba tartozó gépek minden fő mozgása szervo motorral működtetett, így gyors mozgási  sebességeket érünk el, mely a ciklusidőt lerövíditi, nagyobb termelékenységet eredményezve. A  gépcsoport a legkorszerűbb elektronikai, adatátviteli elemekből épül fel, lehetővé téve a mozgások és a technológia nagyon precíz szabályozottt visszacsatolt beállítását. A PE gépcsládot a nagyfokú  villamos energiatakarékosság , valamint a különböző szintű sűrített levegő visszanyerő rendszereknek köszönhető minimalizált légfogyasztás jellemzi.  A töltőgépekkel történő egyszerű összeépítés segítésére a flakonok légpályán keresztül hagyják el a gépet, a vezérlés alpesetben is alkalmas az on-line töltőgépes együttműködésre. A gyors termék és előforma váltást sok alapesetben is beépített, valamint számos opciós megoldás segíti.   A gép  kimondottan a nagyobb űrtartalmű flakonok gyártására  lett kifejlesztve.'
          },
          {
            id: 'pap2',
            category: 'blowingMachine',
            name: 'PAP-2',
            image: 'PAP-1.jpg',
            values: ['2', '0.3 L', '1800',],
            overlayText: 'A PA gépcsaládba -a szervomotorral működtetett nyújtást kivéve- peumatikus munkahengerekkel működtetett gépek tartoznak. A PAP gépek kis hely és energia igénnyel kimondottan a kis űrtartalmú flakonok gyártására lettek kifejlesztve. Ez a géptípus teljesen automata kivitelben készül. A gép vezérlése alkalmas rá, hogy egy töltőgépet on-line módban kiszolgáljon, egy felszerelhető egységgel az elkészült flakonokat  szállítószalagra rendezve helyezi el. A gép a PAP-1 géptípussal azonos méretű, de egyszerre két db kisebb űrtartalmú flakotn tud elkészíteni.'
          },
          {
            id: 'pa2ecoplus',
            category: 'blowingMachine',
            name: 'PA-2 ECO+',
            image: 'PA-2-ECO.jpg',
            values: ['2', '2 L', '1400',],
            overlayText: 'A PA gépcsaládba -a szervomotorral működtetett nyújtást kivéve- peumatikus munkahengerekkel működtetett gépek tartoznak. Ez a géptípus teljesen autpomata üzemmódú kivitelben készül. A gép vezérlése alaklmas rá, hogy egy töltőgépet on-line módban kiszolgáljon,  a töltőgépekkel történő egyszerű összeépítés segítésére a flakonok légpályán keresztül hagyják el a gépet. Az ECO⁺ típus a pneumatikus működésű gépek  korszerűsített, modernizált energiatakarékos modellje.'
          },
          {
            id: 'pe2uni',
            category: 'blowingMachine',
            name: 'PE-2 UNI',
            image: 'PE-2-UNI-és-PE-2-UNI-HOTFILL.jpg',
            values: ['2', '2.5 L', '3200',],
            overlayText: 'A PE gépcsaládba tartozó gépek minden fő mozgása szervo motorral működtetett, így gyors mozgási  sebességeket érünk el, mely a ciklusidőt lerövíditi, nagyobb termelékenységet eredményezve. A  gépcsoport a legkorszerűbb elektronikai, adatátviteli elemekből épül fel, lehetővé téve a mozgások és a technológia nagyon precíz szabályozottt visszacsatolt beállítását. A PE gépcsládot a nagyfokú  villamos energiatakarékosság , valamint a különböző szintű sűrített levegő visszanyerő rendszereknek köszönhető minimalizált légfogyasztás jellemzi.  A töltőgépekkel történő egyszerű összeépítés segítésére a flakonok légpályán keresztül hagyják el a gépet, a vezérlés alpesetben is alkalmas az on-line töltőgépes együttműködésre. A gyors termék és előforma váltást sok alapesetben is beépített, valamint számos opciós megoldás segíti.'
          },
          {
            id: 'pe2uniHotFill',
            category: 'blowingMachine',
            name: 'PE-2 UNI HOT FILL',
            image: 'PE-2-UNI-és-PE-2-UNI-HOTFILL.jpg',
            values: ['2', '2.5 L', '1800',],
            overlayText: 'A PE-2 tíusú gép forrón tölthető flakonok előállítására alkalmas  kiegészítő csomaggal felszerelt gépe. A gép egyszeűen  átállítható a HOTFILL vagy a hagyományos  flakonok gyárátsára.'
          },
          {
            id: 'pe25uni',
            category: 'blowingMachine',
            name: 'PE-25 UNI',
            image: 'PE-25-UNI-és-PA-210.jpg',
            values: ['2', '6 L', '2500',],
            overlayText: 'A PE gépcsaládba tartozó gépek minden fő mozgása szervo motorral működtetett, így gyors mozgási  sebességeket érünk el, mely a ciklusidőt lerövíditi, nagyobb termelékenységet eredményezve. A  gépcsoport a legkorszerűbb elektronikai, adatátviteli elemekből épül fel, lehetővé téve a mozgások és a technológia nagyon precíz szabályozottt visszacsatolt beállítását. A PE gépcsládot a nagyfokú  villamos energiatakarékosság , valamint a különböző szintű sűrített levegő visszanyerő rendszereknek köszönhető minimalizált légfogyasztás jellemzi.  A töltőgépekkel történő egyszerű összeépítés segítésére a flakonok légpályán keresztül hagyják el a gépet, a vezérlés alpesetben is alkalmas az on-line töltőgépes együttműködésre. A gyors termék és előforma váltást sok alapesetben is beépített, valamint számos opciós megoldás segíti.'
          },
          {
            id: 'pa210uni',
            category: 'blowingMachine',
            name: 'PA-210 UNI',
            image: 'PE-25-UNI-és-PA-210.jpg',
            values: ['2', '12 L', '2000 bph',],
            overlayText: 'A PE gépcsaládba tartozó gépek minden fő mozgása szervo motorral működtetett, így gyors mozgási  sebességeket érünk el, mely a ciklusidőt lerövíditi, nagyobb termelékenységet eredményezve. A  gépcsoport a legkorszerűbb elektronikai, adatátviteli elemekből épül fel, lehetővé téve a mozgások és a technológia nagyon precíz szabályozottt visszacsatolt beállítását. A PE gépcsládot a nagyfokú  villamos energiatakarékosság , valamint a különböző szintű sűrített levegő visszanyerő rendszereknek köszönhető minimalizált légfogyasztás jellemzi.  A töltőgépekkel történő egyszerű összeépítés segítésére a flakonok légpályán keresztül hagyják el a gépet, a vezérlés alpesetben is alkalmas az on-line töltőgépes együttműködésre. A gyors termék és előforma váltást sok alapesetben is beépített, valamint számos opciós megoldás segíti.'
          },
          {
            id: 'pe4uni',
            category: 'blowingMachine',
            name: 'PE-4 UNI',
            image: 'PE-4-UNI.jpg',
            values: ['4', '2.5 L', '6400',],
            overlayText: 'A PE gépcsaládba tartozó gépek minden fő mozgása szervo motorral működtetett, így gyors mozgási  sebességeket érünk el, mely a ciklusidőt lerövíditi, nagyobb termelékenységet eredményezve. A  gépcsoport a legkorszerűbb elektronikai, adatátviteli elemekből épül fel, lehetővé téve a mozgások és a technológia nagyon precíz szabályozottt visszacsatolt beállítását. A PE gépcsládot a nagyfokú  villamos energiatakarékosság , valamint a különböző szintű sűrített levegő visszanyerő rendszereknek köszönhető minimalizált légfogyasztás jellemzi.  A töltőgépekkel történő egyszerű összeépítés segítésére a flakonok légpályán keresztül hagyják el a gépet, a vezérlés alpesetben is alkalmas az on-line töltőgépes együttműködésre. A gyors termék és előforma váltást sok alapesetben is beépített, valamint számos opciós megoldás segíti.'
          },
          {
            id: 'pe6uni',
            category: 'blowingMachine',
            name: 'PE-6 UNI',
            image: 'dummy_300x150.png',
            values: ['6', '2.5 L', '9200 bph',],
            overlayText: 'A PE gépcsaládba tartozó gépek minden fő mozgása szervo motorral működtetett, így gyors mozgási  sebességeket érünk el, mely a ciklusidőt lerövíditi, nagyobb termelékenységet eredményezve. A  gépcsoport a legkorszerűbb elektronikai, adatátviteli elemekből épül fel, lehetővé téve a mozgások és a technológia nagyon precíz szabályozottt visszacsatolt beállítását. A PE gépcsládot a nagyfokú  villamos energiatakarékosság , valamint a különböző szintű sűrített levegő visszanyerő rendszereknek köszönhető minimalizált légfogyasztás jellemzi.  A töltőgépekkel történő egyszerű összeépítés segítésére a flakonok légpályán keresztül hagyják el a gépet, a vezérlés alpesetben is alkalmas az on-line töltőgépes együttműködésre. A gyors termék és előforma váltást sok alapesetben is beépített, valamint számos opciós megoldás segíti.'
          },
          {
            id: 'pe12uni',
            category: 'blowingMachine',
            name: 'PE-12 UNI',
            image: 'PE-12-UNI.jpg',
            values: ['12', '0.06 L', '12000',],
            overlayText: 'A PE gépcsaládba tartozó gépek minden fő mozgása szervo motorral működtetett, így gyors mozgási  sebességeket érünk el, mely a ciklusidőt lerövíditi, nagyobb termelékenységet eredményezve. A  gépcsoport a legkorszerűbb elektronikai, adatátviteli elemekből épül fel, lehetővé téve a mozgások és a technológia nagyon precíz szabályozottt visszacsatolt beállítását. A PE gépcslád 12 fészkes kivitele kimondottan kis űrtartalmű flakonok gyártására kifejlesztett célgép.  A töltőgépekkel történő egyszerű összeépítés segítésére a flakonok légpályán keresztül hagyják el a gépet, a vezérlés alpesetben is alkalmas az on-line töltőgépes együttműködésre.'
          },

        ]
        //#endregion

      },
      en: {
        //SITES
        //#region
        sites: [
          {text: 'Home', url: 'home'},
          {text: 'Bottle production', url: 'flakongyartas'},
          {text: 'Attachment manufacturing', url: 'bucket'},
          {text: 'Blowing Machine manufacturing', url: 'flakonfuvogep'},
        ],
        //#endregion
        //REGULAR EXPRESSIONS
        //#region
        re1: 'Enquiry',
        ty:'Thank you!',
        //#endregion
        //MAIN VIEW
        //#region
        h1: 'Thirty years, ',
        h2: 'of excellence',
        h3: ' guaranteed',
        h4: 'Our advanced technological solutions serve to enhance the convenience, productivity, and cost-efficiency of our customers for many years, at a competitive price.',
        h5: 'Machines installed',
        h6: 'Years of experience',
        h7: 'Product category',
        h8: 'Colleague',
        hb1: 'Bottle blowing machine',
        hb2: 'Among the largest manufacturers in Europe',
        hb3: 'Bottle production',
        hb4: 'The expert of custom PET bottle design',
        hb5: 'Excavator and loader attachments',
        hb6: 'Professional buckets are made of these',
        hb7: 'Read more →',
        h9: 'Get a quote!',
        au1: 'About us',
        au2: 'Solutions across industries',
        au3: 'Comprehensive professional experience in the plastic packaging industry, machinery manufacturing, metalworking, and the production of earthmoving machinery attachments.',
        au4: 'Relying on our development and innovation strength, the performance of our motivated colleagues, and the knowledge of our dedicated and recognized experts, we develop our products and technology to contribute to our customers\' growth and our shared success.',
        at: 'Reliable . Sustainable . Durable . Reliable . Sustainable . Durable . Reliable . Sustainable . Durable . Reliable . Sustainable . Durable . Reliable . Sustainable . Durable . Reliable . Sustainable . Durable . Reliable . Sustainable . Durable . Reliable . Sustainable . Durable . Reliable . Sustainable . Durable . Reliable . Sustainable . Durable . Reliable . Sustainable . Durable . Reliable . Sustainable . Durable .',
        h10: 'poliol attachment manufacturing',
        wfd1: 'Dare with us',
        rmb1: 'More info',
        rmb2: 'Get a quote!',
        wfd2: 'Serie and custom production',
        wfd3: 'Catalog products available immediately off the shelf. Custom solutions under short shipping time.\'',
        wfd4: 'Poliol Service Assistance ™',
        wfd5: 'Constant availability, 24-48 h dispatch. Replacement bucket, attachment during the repair period.',
        wfd6: 'Precise to the smallest details ',
        wfd7: 'In-house developed and manufactured work cylinders, and quick coupler systems.',
        wfd8: 'Details',
        h11: 'poliol bottle blowing machine production',
        bmwfd1: 'machine production',
        bmwfd2: 'user-centered innovation',
        bmwfd3: 'Our main priority in the design and manufacturing of POLIOL blow molding machines, based on over 20 years of purely Hungarian know-how knowledge and manufacturing experience, is to build machines that provide lasting value and stable production capacity for our customers.',
        ml1: 'Our locations',
        ml2: 'Locations, 8 countries where the POLIOL manufactured Bottle blowing machines operate, along with absolute partner satisfaction.',
        ml3: 'Romania',
        ml4: 'Algeria',
        ml5: 'Hungary',
        ml6: 'Belgium',
        ml7: 'Croatia',
        ml8: 'North Macedonia',
        ml9: 'Moldavia',
        ml10: 'Serbia',
        pmp1: 'Among the largest manufacturers in Europe',
        pmp2: 'Reliable operation',
        pmp3: 'With the usage of the highest quality electronic and pneumatic parts.',
        pmp4: 'Hydraulic preform filler',
        pmp5: 'for support of full automatic operation',
        pmp6: 'Strong frame',
        pmp7: 'Our machines outstanding stability starts with building a strong frame',
        pmp8: 'Low upkeeping costs',
        pmp9: 'Thanks to the substancial service requirement, and our power-efficient solutions',
        h12: 'Special offer! Get your machine now, from stock!',
        h13: 'Why choose us?',
        h14: 'Available immediately: No waiting time, you can start producing in a matter of days.',
        h15: 'Reliability and quality: Our machines has been proving their excellence for years, in all the fields.',
        h16: 'Favorable prices: We offer our machines at a special price, so you can enjoy the highest technological solutions.',
        h17: 'Don\'t miss the opportunity! Our machines are still available from stock at this point.',
        h18: 'poliol bottle production',
        bpm0: 'Bottle production',
        bpm1: '10 billion bottle',
        bpm2: 'experience',
        bpm3: 'From idea to your doorstep',
        bpm4: 'In-house custom design - 3D modelling - production - labeling, screening - shipping.',
        bpm5: 'Details',
        bpm6: 'Choose online',
        bpm7: 'Webshop products with immediate availability. Custom solutions with short shipping time.',
        bpm8: 'Bottle Webshop',
        bpm9: 'Available in all sizes',
        bpm10: 'Based on demand, we design and manufacture products of various sizes and shapes - ranging from 40 ml bottles to 12 l bottles, cylindrical or square, even products with unique designs.',
        bpm11: 'Details',
        cup1: 'contact',
        cup2: 'Get in touch!',
        cup3: 'Ready to answer your questions',
        cup4: 'message',
        cup5: 'Send a message:',
        cup6: 'Name*',
        cup7: 'Mobile*',
        cup8: 'Message (optional)',
        cup9: 'Send',
        cup10: 'No name given',
        cup11: 'No email address provided',
        cup12: 'No contact phone provided',
        fc1: 'Let\'s help you take it to the next level',
        fc2: 'Excavator attachment -, bucket production',
        fc3: 'János Tősi',
        fc4: 'Blowing Machine production',
        fc5: 'Csaba Lukács',
        fc6: 'Bottle production',
        fc7: 'Adrienn Bíró',
        fc8: 'central',
        fc9: 'All rights reserved © Poliol Kft. - 2024',
        fc10: 'address',
        //#endregion

        //BOTTLE BLOWING SITE
        //#region
        //BOTTLE BLOWING REGULAR
        bb1: 'Fields where our bottles provide daily',
        //BOTTLE BLOWING BANNER
        bbb1: 'HOTFILL technology',
        bbb2: 'Maintain your competitive edge with our revolutionary HOTFILL technology. Maximum heat resistance and safety. Learn more about our state-of-the-art bottle manufacturing methods!',
        bbb3: 'Maintain your competitive edge with our revolutionary HOTFILL technology. Maximum heat resistance and safety.',
        bbb4: 'Sustainability',
        bbb5: 'Sustainable PET bottle manufacturing with eco-friendly solutions. Explore how we protect the planet with our products!',
        bbb6: 'Custom design',
        bbb7: 'From idea to your doorstep, we handle the entire bottle manufacturing process in-house. Our team manages everything in one place, from uniquely designed concepts, to the final production. We efficiently and effectively transform your designs into high-quality PET bottles.',
        bbb8: 'From idea to your doorstep, we handle the entire bottle manufacturing process in-house. Our team manages everything in one place, from uniquely designed concepts, to the final production.',
        bbb9: 'Labeling, screening',
        bbb10: 'Enhance the appeal of PET bottles with our labeling and screen printing services! Excellent quality, customized options to make your products stand out on the shelves!',
        bbb11: 'Enhance the appeal of PET bottles with our labeling and screen printing services!',
        bbmProdProperties: ['No. of cavities', 'Maximal volume', 'Maximal production capacity'],
        //BOTLLE BLOWING PRODUCT CATEGORIES
        bbpc1: 'Chemical industry',
        bbpc2: 'Durable and secure PET bottles for storing chemicals and cleaning agents.',
        bbpc3: 'Food industry',
        bbpc4: 'Hygienic and secure PET bottles, that keep the freshness and quality of food products.',
        bbpc5: 'Mineral oil, lubricants',
        bbpc6: 'Resistant PET bottles that safely store and transport mineral oils and lubricants',
        bbpc7: 'Cosmetics',
        bbpc8: 'Elegant and durable PET bottles that preserve the freshness and quality of cosmetic products.',
        //BOTTLE BLOWING TITLE TEXT IMAGE
        bbtti1: 'why the POLIOL bottles?',
        bbtti2: 'THINK OF SOMETHING.. WE CAN MAKE IT REAL',
        bbtti3: 'Our excellently manufactured, self-designed, and developed plastic-based bottles have consistently proved over the past years.',
        bbtti4: 'Based on the unique requirements of numerous small and medium-sized enterprises, we design and manufacture bottles of various sizes and shapes - from 40 ml bottles to 12 l bottles, cylindrical or square, even bottles with a unique design language - mainly suitable for storing various liquids such as beverages, food, and chemicals. One of our greatest successes is that our 5-liter bottle designed based on our own experience and knowledge, currently used by Szentkirályi Ásványvíz, has received an AWARD OF EXCELLENCE.',
        //BOTTLE BLOWING PRODUCT HEROES
        bbpc11: 'HOTFILL',
        bbpc12: 'Freshness in every drop',
        bbpc13: 'Experience the benefits of our new Hotfill products, allowing high-temperature liquid filling to preserve fresh flavors and extend shelf life. Discover how we elevate PET packaging technology to a new level. Be a part of the Hotfill revolution!',
        bbpc14: ['Juice', 'Tea', 'Energy drinks', 'Honey', 'Smoothie'],
        bbpc21: 'Food products',
        bbpc22: 'In service of hygienic storage for 30 years',
        bbpc23: 'Hygienic and safe PET bottles that preserve the freshness and quality of food products. Explore our solutions for safe storage!',
        bbpc24: ['Water', 'Cooking oil', 'Milk', 'Honey', 'syrup'],
        bbpc31: 'Alcoholic beverage',
        bbpc32: 'Quality in the bottle',
        bbpc33: 'High-quality PET bottles for spirits, preserving the flavors and freshness. Choose the best solution to serve your customers in premium quality!',
        bbpc34: ['Beer', 'Wine', 'Spirits', 'Cider'],
        bbpc41: 'Cosmetics',
        bbpc42: 'Elegance and durability in one bottle',
        bbpc43: 'Elegant and durable PET bottles that preserve the freshness and quality of cosmetic products. Make your brand appealing with our excellent packaging solutions!',
        bbpc44: ['Cremes', 'Body lotions', 'Shower gels', 'Shampoo'],
        bbpc51: 'Mineral oil, lubricants',
        bbpc52: 'Secure storage and transport',
        bbpc53: 'Resistant PET bottles that safely store and transport mineral oils and lubricants. Trust us with the packaging to ensure your products remain in perfect condition under all circumstances!',
        bbpc54: ['Motor oil', 'Brake fluid', 'Lubricants', 'Antifreeze'],
        bbpc61: 'Chemical, cleaning agent',
        bbpc62: 'Safety and reliability',
        bbpc63: 'Durable and safe PET bottles for storing chemicals and cleaning agents. Choose reliability and quality with our products!',
        bbpc64: ['Cleaning agents', 'Disinfectants', 'Washing liquids', 'Industrial chemicals'],

        //#endregion
        //BUCKET PRODUCTION SITE
        //#region
        //BUCKET PRODUCTION REGULAR
        bp1: 'Excavator and loader attachments',
        //BUCKET PRODUCTION BANNER
        bpb1: 'Heavy-duty mining bucket',
        bpb2: 'Innovative solutions, mining buckets with exceptional hardness and durability!',
        bpb3: '7 m³ high dump bucket',
        bpb4: 'We design and manufacture custom solutions tailored to specific customer needs!',
        bpb5: 'Product page',
        //BUCKET PRODUCTION TITLE TEXT IMAGE
        bptti1: 'why POLIOL bucket attachments',
        bptti2: 'Dare with us!',
        bptti3: 'Poliol has over 30 years of experience in manufacturing buckets and attachments. Our company offers innovative solutions that guarantee durability and reliability across all industries.',
        bptti4: 'We offer self-developed and manufactured work cylinders and quick coupler systems. These innovations provide reliability and efficiency for a wide range of applications. Our team has professional tools and knowledge for the refurbishment and modernization of buckets and attachments, ensuring equipment durability and efficiency across various industries. Our products are made from HARDOX materials known for their exceptional strength and durability, ensuring long-term performance. But not only reliability characterizes us, but also swiftness: we deliver catalog products within as little as 2 weeks!',
        //#endregion
        //BOTTLE BLOWING MACHINE PRODUCTION
        //#region
        //BOTTLE BLOWING MACHINE PRODUCTION REGULAR
        bbmp1: 'Our Blowing Machine lines',
        bbmp2: 'Our Blowing Machines',
        bbmp3: 'Additional Machines',
        bbmp4: 'Hide Products',
        //BOTTLE BLOWING MACHINE PRODUCTION BANNER
        bbmpb1: 'PA-2 ECO+',
        bbmpb2: 'The most efficient tool to replace semi-automatic machines.',
        bbmpb3: 'PE-UNI',
        bbmpb4: '30 years of development and reliability in a new attire.',
        //BOTTLE BLOWING MACHINE PRODUCTION CATEGORIES
        bbmpc1: 'Dairies',
        bbmpc2: 'With our machines, you can produce premium quality PET bottles that ensure the safe and long-term storage of dairy products.',
        bbmpc3: 'Breweries, Wineries',
        bbmpc4: 'Innovation above all: manufacture innovative, trendy, and fancy bottles in-house! Product quality and durability!',
        bbmpc5: 'Soft Drink Manufacturing',
        bbmpc6: 'As a soft drink manufacturer, you can produce durable and aesthetic PET bottles. Enhance the attractiveness and safety of your products in-house with our machines!',
        bbmpc7: 'Cosmetic Companies',
        bbmpc8: 'Enhance your brand appeal with durable and elegant PET bottles that ensure the safety and freshness of your products. Produce PET bottles in-house with our machines!',
        bbmpc9: 'Oil Plants',
        bbmpc10: 'Resilient, quality bottles meeting the strictest industrial requirements. Ensure the integrity of the products in-house!',
        //BOTTLE BLOWING MACHINE PRODUCTION TITLE TEXT IMAGE
        bbmptti1: 'why choose Poliol?',
        bbmptti2: 'The most customizable bottle blowing machines in the world',
        bbmptti3: 'Poliol’s bottle blowing machines represent the pinnacle of customization and reliability. With over 30 years of design and manufacturing experience, we ensure that our machines meet even the strictest of industrial requirements.',
        bbmptti4: 'Through our innovative solutions, our machines are not only energy-efficient and space-saving, but also offer quick changeover options, maximizing operational efficiency. The servo motor operation and hydraulic preform filling system guarantee precise and reliable production. For over three decades, we have specialized in linear bottle blowing machines, offering unparalleled expertise and reliability in the market. Our machines are characterized by stable, robust construction, energy efficiency, and space-saving design, ensuring seamless integration into your production environment. Choose Poliol’s bottle blowing machines and enjoy fully customizable, high-quality solutions!',
        //BOTTLE BLOWING MACHINE PRODUCTION PRODUCT HEROES
        bbmppc11: '30 years of quality, just got new.',
        bbmppc12: 'The more versatile UNI machine family stands out from the product list with its fully automatic operation. It guarantees quick changeovers, stronger blowing pressure, and larger bottle volume for our customers.',
        bbmppc13: 'POLIOL Finance™   Leasing Options: Flexible leasing options to meet your financial needs.',
        bbmppc14: ['0.2L - 19L producible volume', 'tool-free changeover in 10 minutes', 'HOTFILL capability', 'Up to 40 Bar blowing pressure for the toughest of challenges'],
        bbmppc21: 'The most efficient tool to replace your semi-automatic machines.',
        bbmppc22: 'In the design of our economy series blowing machines, energy efficiency and sustainability were the primary considerations, while maintaining the maximum level of quality and reliable operation characteristic of our manufacturing.',
        bbmppc23: 'POLIOL Finance™   Leasing Options: Flexible leasing options to meet your financial needs.',
        bbmppc24: ['Fully automatic operation', 'Low energy consumption', 'Air recovery system', 'Compact design', 'Quick, easy installation'],
        bph: ' pcs/hour',
        //#endregion

        //PRODUCTGROUPS
        //#region

        prodGroupLocale:
            [
              {
                id: 'buckets',
                name: 'Attachments',
                types: [
                  {
                    id: 'faipar',
                    name: 'Wood Industry, Agriculture',
                    subtitle: 'Durable and efficient buckets and attachments for the wood industry and agriculture. 30 years of experience, high-performance design and manufacturing line!',
                  },
                  {
                    id: 'foldmunka',
                    name: 'Large-scale Earthmoving',
                    subtitle: 'Strong buckets and attachments for large-scale earthmoving. Increase your efficiency and minimize downtime!',
                  },
                  {
                    id: 'kozmu',
                    name: 'Utility Construction',
                    subtitle: 'Reliable tools for utility construction. Order today and take advantage of our fast delivery service!',
                  },
                  {
                    id: 'varos',
                    name: 'Urban Maintenance',
                    subtitle: 'Professional buckets and attachments for efficient urban maintenance. We design, manufacture, and offer reliability!',
                  },
                  {
                    id: 'banyaszat',
                    name: 'Mining',
                    subtitle: 'Tools made from durable Hardox material for the needs of stone and rock mining. Discover our products that resist even the toughest conditions!',
                  },
                  {
                    id: 'gyorscserelok',
                    name: 'Quick Couplers',
                    subtitle: 'Fast and reliable quick couplers for maximum efficiency. Choose from our innovative solutions, or let\'s design something completely unique together!',
                  },
                ]
              },
              {
                id: 'bottles',
                name: 'Bottles',
                types: [
                  {
                    id: 'vegyipar',
                    image: 'vegyipar.png',
                    name: 'Chemical Industry',
                    subtitle: 'Durable and safe PET bottles for storing chemicals and cleaning agents.',
                  },
                  {
                    id: 'elelmiszeripar',
                    image: 'Uditogyartas.png',
                    name: 'Food Industry',
                    subtitle: 'Hygienic and safe PET bottles that preserve the freshness and quality of food products.',
                  },
                  {
                    id: 'asvanyi-olaj-kenoanyag',
                    image: 'Olajuzemek.png',
                    name: 'Mineral Oil, Lubricant',
                    subtitle: 'Resistant PET bottles that safely store and transport mineral oils and lubricants.',
                  },
                  {
                    id: 'kozmetikai',
                    image: 'Kozmetikai-cegek.png',
                    name: 'Cosmetics',
                    subtitle: 'Elegant and durable PET bottles that preserve the freshness and quality of cosmetic products.',
                  },
                ]
              },
            ],
        //#endregion
        //PRODUCTS
        //#region
        prodLocale: [
          {
            "id": "rezsu-kanal",
            "name": "Slope Bucket",
            "properties": [
              "HARDOX 500 cutting edge",
              "Strong, custom bucket profile",
              "Available in various profiles"
            ],
            "usage": "Recommended for slope cutting, cleaning of ditches and beds, and surface cutting.",
            "text": "The fixed slope bucket is a perfect solution for earthworks, especially for creating ditches, slopes, and embankments. The tool is of high quality and offers outstanding wear resistance, ensuring a long lifespan. It enables more precise and efficient work. Choose the fixed slope bucket for accurate and reliable earthworks!"
          },
          {
            "id": "hidraulikus-rezsu-kanal",
            "name": "Hydraulically Tiltable Slope Bucket",
            "properties": [
              "HARDOX 500 cutting edge and side edge",
              "Massive, S355 steel bucket body",
              "Multiple reinforcing ribs",
              "Modern bucket profile",
              "Extended smoothing surface at no extra cost",
              "Precise work with adjustable flow rate to protect hydraulic cylinders and ensure precision"
            ],
            "usage": "Recommended for all tasks where uneven terrain cannot be managed by the machine alone.",
            "text": "The hydraulic slope bucket revolutionizes the shaping of slopes and ditches, enabling precise and efficient work on any job site. The innovative hydraulic system allows for easy and accurate maneuvering, providing high-performance earthworks for the operator. Made of high-quality materials, the bucket ensures exceptional wear resistance and long service life for reliable use over time.\nChoose the hydraulic version for wide applicability and flexible earthworks! Trust it for the most precise tasks with a tool representing the peak of performance and reliability on every job site!"
          },
          {
            "id": "melyaso-kanal-korom-nelkul",
            "name": "Deep Digging Bucket with Cutting Edge",
            "properties": [
              "Bucket profile designed based on operator feedback",
              "HARDOX 500 cutting edge and side edge",
              "Available in standard, reinforced, and extra-reinforced versions",
              "For all types of excavators"
            ],
            "usage": "Recommended for most subsoil digging and loading tasks.",
            "text": "The deep digging bucket is ideal for deep excavations, such as creating foundations and trenches. Custom-made to meet individual needs, the tool is high quality, reliable, and durable. It is an excellent choice for precise and efficient earthworks. Choose the deep digging bucket for professional work and outstanding results!"
          },
          {
            "id": "rezsu-kanal-varos",
            "name": "Slope Bucket",
            "properties": [
              "HARDOX 500 cutting edge",
              "Strong, custom bucket profile",
              "Available in various profiles"
            ],
            "usage": "Recommended for slope cutting, cleaning of ditches and beds, and surface cutting.",
            "text": "The fixed slope bucket is a perfect solution for earthworks, especially for creating ditches, slopes, and embankments. The tool is of high quality and offers outstanding wear resistance, ensuring a long lifespan. It enables more precise and efficient work. Choose the fixed slope bucket for accurate and reliable earthworks!"
          },
          {
            "id": "melyaso-kanal",
            "name": "Deep Digging Bucket with Teeth",
            "properties": [
              "Bucket profile designed based on operator feedback",
              "HARDOX 500 cutting edge and side edge",
              "Optional tooth system",
              "Equipped with wear strips on the bucket body",
              "Available in standard, reinforced, and extra-reinforced versions",
              "For all types of excavators"
            ],
            "usage": "Recommended for most subsoil digging and loading tasks.",
            "text": "The deep digging bucket is ideal for deep excavations, such as creating foundations and trenches. Custom-made to meet individual needs, the tool is high quality, reliable, and durable. It is an excellent choice for precise and efficient earthworks. Choose the deep digging bucket for professional work and outstanding results!"
          },
          {
            "id": "trapez-kanal-foldmunka",
            "name": "Trapezoid Bucket",
            "properties": [
              "500 HB cutting edge",
              "Available with hydraulically tilting and adjustable side edges",
              "Profile designed based on operator feedback"
            ],
            "usage": "Recommended for ditch digging - symmetrical and asymmetrical ditch profiles that do not require additional work.",
            "text": "Discover the versatility of the trapezoid bucket, which offers ideal solutions for digging, shaping, and maintaining ditches and canals! Made from high-quality material and featuring exceptional wear resistance, it guarantees long life and reliability during the work process. Elevate your work standards with the trapezoid bucket, allowing for more precise and efficient results under all conditions. The best choice when quality and performance matter!"
          },
          {
            "id": "melyaso-kanal-korom-nelkul-kozmu",
            "name": "Deep Digging Bucket with Cutting Edge",
            "properties": [
              "Bucket profile designed based on operator feedback",
              "HARDOX 500 cutting edge and side edge",
              "Available in standard, reinforced, and extra-reinforced versions",
              "For all types of excavators"
            ],
            "usage": "Recommended for most subsoil digging and loading tasks.",
            "text": "The deep digging bucket is ideal for deep excavations, such as creating foundations and trenches. Custom-made to meet individual needs, the tool is high quality, reliable, and durable. It is an excellent choice for precise and efficient earthworks. Choose the deep digging bucket for professional work and outstanding results!"
          },
          {
            "id": "trapez-kanal-kozmuepites",
            "name": "Trapezoid Bucket",
            "properties": [
              "500 HB cutting edge",
              "Available with hydraulically tilting and adjustable side edges",
              "Profile designed based on operator feedback"
            ],
            "usage": "Recommended for ditch digging - symmetrical and asymmetrical ditch profiles that do not require additional work.",
            "text": "Discover the versatility of the trapezoid bucket, which offers ideal solutions for digging, shaping, and maintaining ditches and canals! Made from high-quality material and featuring exceptional wear resistance, it guarantees long life and reliability during the work process. Elevate your work standards with the trapezoid bucket, allowing for more precise and efficient results under all conditions. The best choice when quality and performance matter!"
          },
          {
            "id": "ripper",
            "name": "Ripper Tooth",
            "properties": [
              "Seat made of S355 structural steel",
              "HARDOX 500 sheath",
              "Easily replaceable tooth system",
              "Continuously sharpening design",
              "Compatible with any fastener"
            ],
            "usage": "Recommended for loosening hard, saline soils, and uprooting stones and stumps buried in the ground.",
            "text": "Discover the versatility of the ripper tooth, which is a crucial tool not only for mini machines but also for the largest tracked excavators! This innovative tool allows for easy and effective loosening of hard, saline soil, enabling efficient and effective material handling afterward. Employ a solution that guarantees efficiency and effectiveness on every work site!"
          },
          {
            "id": "hidraulikus-rezsu-kanal-kozmu",
            "name": "Hydraulically Tiltable Slope Bucket",
            "properties": [
              "HARDOX 500 cutting edge and side edge",
              "Massive, S355 steel bucket body",
              "Multiple reinforcing ribs",
              "Modern bucket profile",
              "Extended smoothing surface at no extra cost",
              "Precise work with adjustable flow rate to protect hydraulic cylinders and ensure precision"
            ],
            "usage": "Recommended for all tasks where uneven terrain cannot be managed by the machine alone.",
            "text": "The hydraulic slope bucket revolutionizes the shaping of slopes and ditches, enabling precise and efficient work on any job site. The innovative hydraulic system allows for easy and accurate maneuvering, providing high-performance earthworks for the operator. Made of high-quality materials, the bucket ensures exceptional wear resistance and long service life for reliable use over time.\nChoose the hydraulic version for wide applicability and flexible earthworks! Trust it for the most precise tasks with a tool representing the peak of performance and reliability on every job site!"
          },
          {
            "id": "erositett-melyaso-kanal",
            "name": "Mining Bucket",
            "properties": [
              "Hardox HiTuf 500 bucket body",
              "Welds made with wear-resistant special filler material",
              "For the toughest mining tasks",
              "Custom equipment levels"
            ],
            "usage": "Recommended for the toughest mining jobs and demands. Long-term solution available at local prices.",
            "text": "The mining bucket is specifically designed for use in mining and heavy industrial environments, handling large quantities of material. The tool is high quality and offers outstanding wear resistance, ensuring a long lifespan. It helps carry out serious material handling tasks efficiently. Choose our mining bucket for reliable and durable material handling!"
          },
          {
            "id": "erositett-rakodo-kanal",
            "name": "Heavy-Duty Loader Bucket (HDX)",
            "properties": [
              "Designed for the heaviest mining loading operations",
              "Custom sizes for all machine types",
              "Every part of the bucket can be equipped with wear segments and segments",
              "Custom bucket profile and cutting edge shape selection"
            ],
            "usage": "Mining version specifically for mining loading tasks. Custom equipment adjusted to customer needs.",
            "text": "Discover the pinnacle of performance and durability with the reinforced loader bucket, designed specifically for the toughest mining and industrial challenges. The robust design and oversized components deliver outstanding performance even under the most extreme working conditions. The wear-exposed parts of the bucket are protected with special wear-resistant plates, while the extra reinforced side edge ensures maximum protection and durability."
          },
          {
            "id": "rakodo-kanal",
            "name": "Loader Bucket",
            "properties": [
              "For all types of loaders",
              "Available with HARDOX 500 bucket body",
              "Optional tooth set available in various sizes",
              "Available in both opening and high-dump versions",
              "Produced in standard, reinforced, and mining versions"
            ],
            "usage": "Primarily recommended for loading tasks, from small to large quantities. For all machine types. Depending on the material, available with a cutting edge or tooth equipment.",
            "text": "The loader bucket is versatile for loading and transporting materials such as sand, gravel, and soil. Custom-made to meet your needs, the tool is high quality and reliable. It enhances work efficiency with its reliability and durability. Choose our loader bucket for all your loading tasks!"
          },
          {
            "id": "ripper-banyaszat",
            "name": "Ripper Tooth",
            "properties": [
              "Seat made of S355 structural steel",
              "HARDOX 500 sheath",
              "Easily replaceable tooth system",
              "Continuously sharpening design",
              "Compatible with any fastener"
            ],
            "usage": "Recommended for loosening hard, saline soils, and uprooting stones and stumps buried in the ground.",
            "text": "Discover the versatility of the ripper tooth, which is a crucial tool not only for mini machines but also for the largest tracked excavators! This innovative tool allows for easy and effective loosening of hard, saline soil, enabling efficient and effective material handling afterward. Employ a solution that guarantees efficiency and effectiveness on every work site!"
          },
          {
            "id": "hidraulikus-kotro-XCMG-gyorscserelo",
            "name": "Hydraulic/Mechanical Excavator Quick Coupler",
            "properties": [
              "A key tool for the versatility of the excavator. Saving time, human and machine power in hydraulic and mechanical versions for all machine types",
              "A key tool for the versatility of the excavator",
              "Saving time, human and machine power",
              "Available in both hydraulic and mechanical versions!",
              "For all machine types"
            ],
            "usage": "Recommended for excavators used for multiple purposes with frequent tool changes. In today's developing world, it is almost an essential accessory for an excavator.",
            "text": "The hydraulic excavator quick coupler allows for rapid tool changes, significantly enhancing the machine's versatility. Custom-made to meet specific needs, the tool is high quality and durable. It allows you to complete different earthworks more quickly and efficiently. Choose the hydraulic excavator quick coupler for flexible and quick work!"
          },
          {
            "id": "hidraulikus-rakodo-VOLVO-gyorscserelo",
            "name": "Hydraulic Loader Quick Coupler",
            "properties": [
              "Enables multi-purpose use of the loader."
            ],
            "usage": "Recommended for loaders used for various purposes, with frequent tool changes.",
            "text": "Designed for the construction of drainage ditches, canals, and slopes."
          },
          {
            "id": "balavilla",
            "name": "Bale Fork",
            "properties": [
              "Own profile attachment",
              "Simple design and cost-effective pricing",
              "With replaceable spikes",
              "For any fastener"
            ],
            "usage": "Recommended for handling bales in the agricultural sector.",
            "text": "The bale fork is excellent for moving and handling straw bales, hay bales, and other agricultural materials. Custom-made to meet specific needs, the tool is reliable and durable. It facilitates and enhances the efficiency of bale handling in daily use."
          },
          {
            "id": "tragyavilla",
            "name": "Manure Fork",
            "properties": [
              "Robust, simple body made of S355 structural steel",
              "Replaceable fork tine",
              "Hydraulic clamping crocodile version available",
              "Also available in a simple, lower fork tine version"
            ],
            "usage": "Recommended for agricultural work, manure handling, and loading fibrous plant waste.",
            "text": "The manure fork is excellent for handling and loading manure in agricultural areas. Custom-made to meet specific needs, the tool is high quality, reliable, and durable. It allows for easy and efficient manure handling tasks. Be a part of the efficient mechanization of agriculture."
          },
          {
            "id": "konnyuanyag-kanal",
            "name": "Light Material Bucket",
            "properties": [
              "Spill guard bucket profile, Hardox 500 cutting edge, large volume without unnecessary weight, optional bolt-on cutting edge",
              "Spill guard bucket profile",
              "HARDOX 500 cutting edge",
              "Large volume, without unnecessary weight",
              "Optional bolt-on cutting edge"
            ],
            "usage": "Recommended for loading large quantities of agricultural spreadable materials, bulk feed, in both fixed and high-dump versions.",
            "text": "The light material bucket is ideal for loading and moving light materials such as wood chips, compost, and litter. The tool is high quality and offers outstanding wear resistance, ensuring a long lifespan. It is a perfect choice for efficient and precise work. Choose the light material bucket for efficient material handling tasks!"
          },
          {
            "id": "magasuritesu-kanal",
            "name": "High Dump Bucket",
            "properties": [
              "Massive bucket body",
              "HARDOX 500 cutting edge",
              "With custom spill guard design",
              "Actual cubic meter calculation"
            ],
            "usage": "Recommended for high dumping tasks.",
            "text": "The high dump bucket is designed for the largest industrial tasks, enabling the fast and efficient handling of large quantities of material. The robust design and extra reinforced structure ensure maximum performance even under the heaviest load. Made from high-quality material, the bucket guarantees exceptional wear resistance, providing a long lifespan for continuous use.\nDon't let big tasks stop you - choose the high dump bucket for efficiency and reliability! Experience peak performance on your work sites with a tool that ensures the highest productivity and results under all conditions!"
          },
          {
            "id": "raklapvilla",
            "name": "Pallet Fork",
            "properties": [
              "Wide load capacity range",
              "For all types of machines, not just loaders",
              "CE certification"
            ],
            "usage": "Recommended primarily for moving palletized goods, as well as any other load that matches the load capacity.",
            "text": "The pallet fork is ideal for the safe and efficient movement of pallets and different loads. The tool is high quality, reliable, and durable. Custom-made according to specific needs, the attachment enables easier and more precise execution of loading tasks. Choose the pallet fork for excellent quality and efficiency!"
          }
        ],
        //#endregion
        //PRODUCT PAGE
        //#region
        pp1: 'Properties',
        pp2: 'Get a quote',
        pp3: 'Recommended usage',
        pp4: 'Gallery',
        //#endregion
      },
      ro: {
        //REGULAR EXPRESSIONS
        //#region
        re1: 'Sunt interesat',
        ty:'Mulțumesc!',
        //#endregion

        //SITES
        //#region
        sites: [
          {text: 'Pagina principală', url: 'home'},
          {text: 'Producție de flacoane', url: 'flakongyartas'},
          {text: 'Producție de cupe', url: 'bucket'},
          {text: 'Producție de mașini de suflat flacoane', url: 'flakonfuvogep'},
        ],
        //#endregion

        //MAIN VIEW
        //#region
        h1: 'Treizeci de ani, ',
        h2: 'experiența ',
        h3: 'garanției',
        h4: 'Soluțiile noastre tehnologice moderne servesc confortului, productivității și rentabilității clienților noștri, la un preț competitiv, pentru mulți ani.',
        h5: 'Mașină instalată',
        h6: 'Ani de experiență',
        h7: 'Categorie de produse',
        h8: 'Angajat',
        hb1: 'Producție de mașini de suflat flacoane',
        hb2: 'Cel mai mare producător de mașini de suflat flacoane din Ungaria',
        hb3: 'Producție de flacoane',
        hb4: 'Expert în proiectarea flacoanelor PET personalizate',
        hb5: 'Producție de accesorii',
        hb6: 'Cupe profesionale produse la noi',
        hb7: 'Citește mai mult →',
        h9: 'Solicitați o ofertă!',
        au1: 'Despre noi',
        au2: 'soluții la nivel de industrie',
        au3: 'Experiență profesională cuprinzătoare în industria ambalajelor din plastic, fabricația de mașini, prelucrarea metalului, precum și producția de accesorii pentru utilaje de construcții',
        au4: 'Ne bazăm pe forța noastră de inovare și dezvoltare, pe performanța angajaților noștri motivați și pe cunoștințele experților noștri angajați și recunoscuți pentru a dezvolta produsele și tehnologiile noastre, contribuind astfel la creșterea clienților noștri și la succesul comun.',
        at: 'Fiabil . Durabil . Permanent . Fiabil . Durabil . Permanent . Fiabil . Durabil . Permanent . Fiabil . Durabil . Permanent . Fiabil . Durabil . Permanent . Fiabil . Durabil . Permanent . Fiabil . Durabil . Permanent . Fiabil . Durabil . Permanent . Fiabil . Durabil . Permanent . Fiabil . Durabil . Permanent . Fiabil . Durabil . Permanent.',
        h10: 'producția de accesorii pentru poliol',
        wfd1: 'Curajul nostru',
        rmb1: 'Mai multe informații',
        rmb2: 'Sunt interesat',
        wfd2: 'Producție de serie și personalizată',
        wfd3: 'Produse din catalog disponibile imediat din depozit. Soluții personalizate pentru cerințe unice ale clienților, cu un timp de execuție scurt.',
        wfd4: 'Asistență pentru servicii Poliol ™',
        wfd5: 'Disponibilitate continuă, livrare în 24-48 ore. Asigurarea unei cupe de schimb sau accesorii pe durata reparației.',
        wfd6: 'Precizie în detalii',
        wfd7: 'Sisteme de cilindri și schimbătoare rapide dezvoltate și produse intern.',
        wfd8: 'Detalii',
        h11: 'producția de mașini de suflat flacoane pentru poliol',
        bmwfd1: 'Producție de mașini',
        bmwfd2: 'inovație centrată pe client',
        bmwfd3: 'POLIOL flacon suflant ale soluțiilor moderne excelente, la prețuri competitive. Experiența și cunoștințele în peste 20 de ani concentrându-se pe proiecte practice și economice pentru client.',
        ml1: 'Locațiile noastre',
        ml2: 'La fața locului, în 8 țări din lume funcționează mașini de suflat flacoane produse de POLIOL, cu satisfacție maximă a clienților.',
        ml3: 'România',
        ml4: 'Algeria',
        ml5: 'Ungaria',
        ml6: 'Belgia',
        ml7: 'Croația',
        ml8: 'Macedonia de Nord',
        ml9: 'Moldova',
        ml10: 'Serbia',
        pmp1: 'Cel mai mare producător de mașini de suflat flacoane din Ungaria',
        pmp2: 'Funcționare fiabilă',
        pmp3: 'Cu utilizarea componentelor electronice și pneumatice de cea mai înaltă calitate',
        pmp4: 'Încărcător hidraulic de preforme',
        pmp5: 'pentru sprijinirea automatizării complete',
        pmp6: 'Cadru puternic',
        pmp7: 'Stabilitatea ridicată a utilajelor noastre începe cu construcția cadrelor solide',
        pmp8: 'Costuri favorabile de operare',
        pmp9: 'Datorită cerințelor scăzute de întreținere și soluțiilor eficiente din punct de vedere energetic',
        h12: 'Ofertă specială! Obțineți acum utilajele noastre din stoc!',
        h13: 'De ce să alegeți acum?',
        h14: 'Disponibil imediat: Fără timp de așteptare, puteți folosi noua dvs. mașină în scurt timp.',
        h15: 'Fiabilitate și calitate: Utilajele noastre și-au demonstrat excelența de-a lungul anilor, indiferent de industrie.',
        h16: 'Prețuri favorabile: Le oferim la un preț special, astfel încât să puteți beneficia de cea mai bună tehnologie în condiții favorabile.',
        h17: 'Nu ratați această oportunitate! Utilajele noastre sunt încă disponibile din stoc.',
        h18: 'producția de flacoane pentru poliol',
        bpm0: 'Producție de flacoane',
        bpm1: '10 miliarde de flacoane',
        bpm2: 'experiență',
        bpm3: 'De la concept la flacon final',
        bpm4: 'Proiectare design personalizat - modelare 3D - producție - etichetare, serigrafie în casă.',
        bpm5: 'Detalii',
        bpm6: 'Alegeți online',
        bpm7: 'Produse din webshop disponibile imediat. Soluții personalizate pentru cerințe unice ale clienților, cu un timp de execuție scurt.',
        bpm8: 'Flacon Webshop',
        bpm9: 'în toate dimensiunile',
        bpm10: 'Proiectăm și producem flacoane de diferite dimensiuni, forme - de la flacoane de 40 ml volum până la flacoane de 12 l volum, cilindrice sau pătrate, dar chiar și cu forme unice',
        bpm11: 'Detalii',
        cup1: 'contact',
        cup2: 'Contactați-ne!',
        cup3: 'Nu ezitați să ne contactați',
        cup4: 'mesaj',
        cup5: 'scrieți-ne:',
        cup6: 'Nume*',
        cup7: 'Telefon*',
        cup8: 'Mesaj (opțional)',
        cup9: 'Trimiteți mesajul',
        cup10: 'Nu ați introdus un nume',
        cup11: 'Nu ai furnizat un număr de telefon',
        fc1: 'Vă ajutăm să faceți un pas înainte',
        fc2: 'Producție accesorii și cupe',
        fc3: 'Tősi János',
        fc4: 'Producție de mașini de suflat flacoane',
        fc5: 'Lukács Csaba',
        fc6: 'Producție de flacoane',
        fc7: 'Bíró Adrienn',
        fc8: 'sediu central',
        fc9: 'Toate drepturile rezervate © Poliol Kft. - 2024',
        fc10: 'adresă',
        //#endregion

        //BOTTLE BLOWING SITE
        //#region
        //BOTTLE BLOWING REGULAR
        bb1: 'Industrie în care flacoanele noastre au performat deja',
        //BOTTLE BLOWING BANNER
        bbb1: 'Tehnologie HOTFILL',
        bbb2: 'Mențineți-vă avantajul competitiv cu tehnologia noastră revoluționară HOTFILL. Rezistență maximă la căldură și siguranță. Aflați mai multe despre cele mai moderne metode de fabricare a flacoanelor!',
        bbb3: 'Mențineți-vă avantajul competitiv cu tehnologia noastră revoluționară HOTFILL. Rezistență maximă la căldură și siguranță.',
        bbb4: 'Conștientizare ecologică',
        bbb5: 'Producție durabilă de flacoane PET cu soluții ecologice. Descoperiți cum protejăm planeta cu produsele noastre!',
        bbb6: 'Proiectare personalizată',
        bbb7: 'De la concept la flacon final, întregul proces de fabricare a flacoanelor este realizat în casă. De la conceptele personalizate la producția produselor, echipa noastră gestionează totul într-un singur loc. Transformăm eficient și eficace ideile dumneavoastră în flacoane PET de înaltă calitate.',
        bbb8: 'De la concept la flacon final, întregul proces de fabricare a flacoanelor este realizat în casă. De la conceptele personalizate la producția produselor, echipa noastră gestionează totul într-un singur loc.',
        bbb9: 'Etichetare, serigrafie',
        bbb10: 'Creșteți atracția flacoanelor PET cu serviciile noastre de etichetare și serigrafie! Oferim opțiuni de înaltă calitate, personalizate pentru a ajuta produsele dvs. să iasă în evidență pe rafturi!',
        bbb11: 'Creșteți atracția flacoanelor PET cu serviciile noastre de etichetare și serigrafie!',
        //BOTLLE BLOWING PRODUCT CATEGORIES
        bbpc1: 'Industria chimică',
        bbpc2: 'Flacoane PET durabile și sigure pentru stocarea de substanțe chimice și produse de curățenie.',
        bbpc3: 'Industria alimentară',
        bbpc4: 'Flacoane PET igienice și sigure, care păstrează prospețimea și calitatea alimentelor.',
        bbpc5: 'Uleiuri minerale, lubrifianți',
        bbpc6: 'Flacoane PET rezistente, care stochează și transportă în siguranță uleiuri minerale și lubrifianți.',
        bbpc7: 'Cosmetică',
        bbpc8: 'Flacoane PET elegante și durabile, care păstrează prospețimea și calitatea produselor cosmetice.',
        //BOTTLE BLOWING TITLE TEXT IMAGE
        bbtti1: 'de ce flacoane POLIOL',
        bbtti2: 'DUMNEAVOASTRĂ IMAGINAȚI, NOI REALIZĂM',
        bbtti3: 'Flacoanele noastre din materiale plastice de înaltă calitate, proiectate și dezvoltate în mod unic, au performat constant în ultimii ani.',
        bbtti4: 'Proiectăm și producem flacoane de diferite dimensiuni, forme – de la flacoane de 40 ml volum până la flacoane de 12 l volum, cilindrice sau pătrate, dar chiar și cu forme unice – în funcție de nevoile fiecărei mici și mijlocii întreprinderi. Unul dintre cele mai mari succese ale noastre este flaconul de 5 litri de apă minerală Szentkirályi, pe care l-am proiectat pe baza experienței și cunoștințelor noastre și care a primit un Premiu de Excelență.',
        //BOTTLE BLOWING PRODUCT HEROES
        bbpc11: 'HOTFILL',
        bbpc12: 'Prospețime în fiecare picătură',
        bbpc13: 'Bucurați-vă de avantajele noilor noastre produse Hotfill, care permit umplerea lichidelor la temperaturi ridicate pentru a păstra prospețimea gusturilor și pentru o durabilitate îndelungată. Experimentați cum ridicăm ambalarea PET la un nou nivel. Fiți parte din revoluția Hotfill!',
        bbpc14: ['Suc de fructe', 'Ceai', 'Energie', 'Miere', 'Smoothie'],
        bbpc21: 'Alimente',
        bbpc22: 'În slujba depozitării igienice de 30 de ani',
        bbpc23: 'Flacoane PET igienice și sigure, care păstrează prospețimea și calitatea alimentelor. Descoperă soluțiile noastre pentru depozitare sigură!',
        bbpc24: ['Apă', 'Ulei alimentar', 'Lapte', 'Miere', 'Sirop'],
        bbpc31: 'Industira băuturilor alcoolice',
        bbpc32: 'Calitate și în sticlă',
        bbpc33: 'Flacoane PET de înaltă calitate pentru băuturi alcoolice, care păstrează aromele și prospețimea. Alegeți cea mai bună soluție pentru a servi clienții dvs. în calitate premium!',
        bbpc34: ['Bere', 'Vin', 'Palincă', 'Cidru'],
        bbpc41: 'Cosmetică',
        bbpc42: 'Eleganță și durabilitate într-un flacon',
        bbpc43: 'Flacoane PET elegante și durabile, care păstrează prospețimea și calitatea produselor cosmetice. Faceți-vă marca mai atrăgătoare cu soluțiile noastre de ambalare de înaltă calitate!',
        bbpc44: ['Creme', 'Lapte de corp', 'Parfumuri', 'Șampoane'],
        bbpc51: 'Uleiuri minerale, lubrifianți',
        bbpc52: 'Stocare și transport sigur',
        bbpc53: 'Flacoane PET rezistente, care stochează și transportă în siguranță uleiuri minerale și lubrifianți. Răspundem la cerințele stricte ale industriei, asigurând păstrarea produselor în stare perfectă în orice condiții!',
        bbpc54: ['Ulei de motor', 'Lichid de frână', 'Lubrifianți', 'Lichid de răcire'],
        bbpc61: 'Chimicale, produse de curățat',
        bbpc62: 'Siguranță și fiabilitate',
        bbpc63: 'Flacoane PET durabile și sigure pentru stocarea de substanțe chimice și produse de curățenie. Alegeți durabilitatea și calitatea cu produsele noastre!',
        bbpc64: ['Produse de curățat', 'Dezinfectanți', 'Detergenți', 'Chimicale industriale'],
        //#endregion

        //BUCKET PRODUCTION SITE
        //#region
        //BUCKET PRODUCTION REGULAR
        bp1: 'Accesorii noastre',
        //BUCKET PRODUCTION BANNER
        bpb1: 'Cuspic de minerit cu capacitate mare',
        bpb2: 'Soluții inovatoare, cupe de minerit cu rezistență și durabilitate excepționale!',
        bpb3: 'Cupă de înălțime mare de 7 m³',
        bpb4: 'Proiectăm și producem soluții unice, întotdeauna în funcție de cerințele specifice ale clienților!',
        bpb5: 'Fișă de produs',
        //BUCKET PRODUCTION CATEGORIES
        bpc1: 'Industria lemnului, Agricultură',
        bpc2: 'Cupa și accesorii durabile și eficiente pentru industria lemnului și agricultură. 30 de ani de experiență, proiectare și producție performante!',
        bpc3: 'Lucrări grele de teren',
        bpc4: 'Cupe și accesorii rezistente pentru lucrări grele de teren. Creșteți eficiența și minimizați timpul de nefuncționare!',
        bpc5: 'Construcții de utilități',
        bpc6: 'Instrumente fiabile pentru construcții de utilități. Comandați astăzi și beneficiați de serviciul nostru rapid de livrare!',
        bpc7: 'Întreținerea orașelor',
        bpc8: 'Cupe și accesorii profesionale pentru întreținerea eficientă a orașelor. Proiectăm, producem și oferim fiabilitate!',
        bpc9: 'Minerit',
        bpc10: 'Instrumente fabricate din material Hardox durabile, pentru nevoile minelor de piatră și rocă. Descoperiți produsele noastre, care rezistă și în cele mai dure condiții!',
        bpc11: 'Schimbători rapidi de accesorii',
        bpc12: 'Schimbători rapidi și fiabili pentru eficiență maximă.',
//BUCKET PRODUCTION TITLE TEXT IMAGE
        bptti1: 'de ce POLIOL accesorii pentru cupe',
        bptti2: 'Aveți curajul cu noi!',
        bptti3: 'Poliol are peste 30 de ani de experiență în producția de cupe și accesorii. Compania noastră oferă soluții inovatoare care garantează durabilitatea și fiabilitatea în toate industriile.',
        bptti4: 'Oferim cilindri de lucru și sisteme de cuplare rapidă dezvoltate și fabricate intern. Aceste inovații asigură fiabilitate și eficiență pentru o gamă largă de aplicații. Echipa noastră dispune de unelte profesionale și cunoștințe pentru recondiționarea și modernizarea cupe și accesoriilor, garantând durabilitatea și eficiența echipamentelor în diverse industrii. Produsele noastre sunt fabricate din materiale HARDOX, cunoscute pentru rezistența și durabilitatea lor excepționale, asigurând performanțe pe termen lung. Dar nu doar fiabilitatea ne caracterizează, ci și rapiditatea: livrăm produsele din catalog în cel mult 2 săptămâni!',

//BOTTLE BLOWING MACHINE PRODUCTION
//#region
//BOTTLE BLOWING MACHINE PRODUCTION REGULAR
        bbmp1: 'Tipurile noastre de mașini de suflat flacoane',
        bbmp2: 'Mașinile noastre de suflat flacoane',
        bbmp3: 'Mașini adiționale',
        bbmp4: 'Ascunde produse',
        bbmProdProperties: ['Număr de cavități', 'Volum maxim', 'Capacitate maximă de producție'],

//BOTTLE BLOWING MACHINE PRODUCTION BANNER
        bbmpb1: 'PA-2 ECO+',
        bbmpb2: 'Cel mai eficient instrument pentru înlocuirea mașinilor semi-automate.',
        bbmpb3: 'PE-UNI',
        bbmpb4: '30 de ani de dezvoltare și fiabilitate într-o nouă haină.',

//BOTTLE BLOWING MACHINE PRODUCTION CATEGORIES
        bbmpc1: 'Fabrici de lactate',
        bbmpc2: 'Cu mașinile noastre, puteți produce flacoane PET de înaltă calitate care asigură păstrarea în siguranță și pe termen lung a produselor lactate.',
        bbmpc3: 'Berării, crame',
        bbmpc4: 'Inovație peste tot: produceți flacoane inovatoare, moderne și elegante in-house! Calitate și durabilitate a produselor!',
        bbmpc5: 'Producția de băuturi răcoritoare',
        bbmpc6: 'Ca producător de băuturi răcoritoare, puteți produce flacoane PET durabile și estetice. Asigurați atractivitatea și siguranța produselor dumneavoastră in-house cu mașinile noastre!',
        bbmpc7: 'Companii de cosmetice',
        bbmpc8: 'Îmbunătățiți atractivitatea mărcii dumneavoastră cu flacoane PET durabile și elegante care asigură siguranța și prospețimea produselor. Produceți flacoane PET in-house cu mașinile noastre!',
        bbmpc9: 'Fabrici de uleiuri',
        bbmpc10: 'Flacoane rezistente, de calitate, care respectă cele mai stricte cerințe industriale. Asigurați integritatea produselor in-house!',

//BOTTLE BLOWING MACHINE PRODUCTION TITLE TEXT IMAGE
        bbmptti1: 'de ce să alegeți Poliol?',
        bbmptti2: 'Cele mai personalizabile mașini de suflat flacoane din lume',
        bbmptti3: 'Mașinile de suflat flacoane Poliol reprezintă vârful personalizării și fiabilității. Cu peste 30 de ani de experiență în proiectare și producție, asigurăm că mașinile noastre respectă cele mai stricte cerințe industriale.',
        bbmptti4: 'Prin soluțiile noastre inovatoare, mașinile noastre sunt nu doar eficiente energetic și economisesc spațiu, ci oferă și opțiuni rapide de schimbare, maximizând eficiența operațională. Sistemul de operare cu motor servo și sistemul hidraulic de încărcare a materialului preformat asigură o producție precisă și fiabilă. De peste trei decenii, ne specializăm în mașini liniare de suflare a flacoanelor, oferind expertiză și fiabilitate de neegalat pe piață. Mașinile noastre se caracterizează printr-o construcție stabilă și robustă, eficiență energetică și un design economic de spațiu, asigurând integrarea fără probleme în mediul dumneavoastră de producție. Alegeți mașinile de suflat flacoane Poliol și bucurați-vă de soluții complet personalizabile și de înaltă calitate!',

//BOTTLE BLOWING MACHINE PRODUCTION PRODUCT HEROES
        bbmppc11: '30 de ani de calitate într-o haină nouă.',
        bbmppc12: 'Familia de mașini UNI, mai versatilă în utilizare, se evidențiează prin funcționarea complet automată. Garantează schimbări rapide, o presiune de suflare mai mare și flacoane de volum mai mare pentru clienții noștri.',
        bbmppc13: 'POLIOL Finance™ Opțiuni de leasing: Opțiuni de leasing flexibile pentru a satisface nevoile dumneavoastră financiare.',
        bbmppc14: ['Volume producibile între 0.2L - 19L', 'schimbare fără unelte în 10 minute', 'capacitate HOTFILL', 'Până la 40 Bar presiune de suflare pentru cele mai mari provocări'],
        bbmppc21: 'Cel mai eficient instrument pentru înlocuirea mașinilor semi-automate.',
        bbmppc22: 'În proiectarea mașinilor noastre de suflat de serie economia, eficiența energetică și durabilitatea au fost priorități principale, menținând în același timp nivelul maxim de calitate și funcționare fiabilă caracteristice producției noastre.',
        bbmppc23: 'POLIOL Finance™ Opțiuni de leasing: Opțiuni de leasing flexibile pentru a satisface nevoile dumneavoastră financiare.',
        bbmppc24: ['Funcționare complet automată', 'Consum redus de energie', 'Sistem de recuperare a aerului', 'Design compact', 'Instalare rapidă și ușoară'],
        bph: ' buc/ora',

//PRODUCT PAGE
//#region
        pp1: 'Caracteristici',
        pp2: 'Solicitați o ofertă',
        pp3: 'Utilizare recomandată',
        pp4: 'Galerie',
//#endregion
//PRODUCT GROUPS
//#region
        prodGroupLocale:
            [
              {
                id: 'buckets',
                name: 'Accesorii',
                types: [
                  {
                    id: 'faipar',
                    name: 'Industria Lemnului, Agricultură',
                    subtitle: 'Cupe și accesorii durabile și eficiente pentru industria lemnului și agricultură. 30 de ani de experiență, linie de proiectare și producție de înaltă performanță!',
                  },
                  {
                    id: 'foldmunka',
                    name: 'Lucrări pe scară largă de terasament',
                    subtitle: 'Cupe și accesorii rezistente pentru lucrări pe scară largă de terasament. Creșteți eficiența și minimizați timpul de nefuncționare!',
                  },
                  {
                    id: 'kozmu',
                    name: 'Construcții de utilități',
                    subtitle: 'Unelte de încredere pentru construcții de utilități. Comandați astăzi și profitați de serviciul nostru rapid de livrare!',
                  },
                  {
                    id: 'varos',
                    name: 'Întreținerea orașelor',
                    subtitle: 'Cupe și accesorii profesionale pentru întreținerea eficientă a orașelor. Proiectăm, producem și oferim fiabilitate!',
                  },
                  {
                    id: 'banyaszat',
                    name: 'Minerit',
                    subtitle: 'Unelte fabricate din material durabil Hardox pentru nevoile minelor de piatră și rocă. Descoperiți produsele noastre care rezistă chiar și în cele mai dure condiții!',
                  },
                  {
                    id: 'gyorscserelok',
                    name: 'Cuplaje rapide',
                    subtitle: 'Cuplaje rapide și de încredere pentru eficiență maximă. Alegeți din soluțiile noastre inovatoare sau proiectăm împreună ceva complet unic!',
                  },
                ]
              },
              {
                id: 'bottles',
                name: 'Flacoane',
                types: [
                  {
                    id: 'vegyipar',
                    image: 'vegyipar.png',
                    name: 'Industria Chimică',
                    subtitle: 'Flacoane PET durabile și sigure pentru stocarea substanțelor chimice și a produselor de curățare.',
                  },
                  {
                    id: 'elelmiszeripar',
                    image: 'Uditogyartas.png',
                    name: 'Industria Alimentară',
                    subtitle: 'Flacoane PET igienice și sigure care păstrează prospețimea și calitatea alimentelor.',
                  },
                  {
                    id: 'asvanyi-olaj-kenoanyag',
                    image: 'Olajuzemek.png',
                    name: 'Uleiuri Minerale, Lubrifianți',
                    subtitle: 'Flacoane PET rezistente care stochează și transportă în siguranță uleiuri minerale și lubrifianți.',
                  },
                  {
                    id: 'kozmetikai',
                    image: 'Kozmetikai-cegek.png',
                    name: 'Cosmetice',
                    subtitle: 'Flacoane PET elegante și durabile care păstrează prospețimea și calitatea produselor cosmetice.',
                  },
                ]
              },
            ],
//#endregion
        prodLocale: [
          {
            id: 'magasuritesu-kanal',
            name: 'Cupă de descărcare înaltă',
            properties: [
              'Corp robust de cupă',
              'Lamă de tăiere HARDOX 500',
              'Design unic anti-scurgere',
              'Calcul real al volumului'
            ],
            usage: 'Recomandată pentru sarcinile de descărcare la înălțime.',
            text: 'Cupa de descărcare înaltă este un echipament proiectat pentru cele mai mari sarcini industriale, permițând mutarea rapidă și eficientă a materialelor în cantități mari. Construcția robustă și structura extra întărită asigură performanța maximă chiar și în condițiile de încărcare extremă. Fabricată din materiale de înaltă calitate, cupa garantează o rezistență excepțională la uzură, asigurând o durată lungă de viață pentru utilizarea continuă.\nNu lăsați sarcinile mari să vă oprească - alegeți cupa de descărcare înaltă pentru eficiență și fiabilitate! Conduceți performanța pe șantier cu un echipament care asigură cea mai mare productivitate și succes în orice condiții!'
          },
          {
            id: 'raklapvilla',
            name: 'Furcă pentru paleți',
            properties: [
              'Gamă largă de capacitate de încărcare',
              'Fabricată pentru toate tipurile de utilaje, nu doar pentru încărcătoare',
              'Certificat CE',
            ],
            usage: 'Recomandată în primul rând pentru manipularea mărfurilor paletizate, precum și pentru orice alt obiect care se încadrează în capacitatea de încărcare.',
            text: 'Furca pentru paleți este ideală pentru transportul sigur și eficient al paleților și diferitelor încărcături. Echipamentul este de înaltă calitate, fiabil și durabil. Personalizată în funcție de nevoile individuale, furca pentru paleți facilitează executarea sarcinilor de manipulare mai ușor și mai precis. Alegeți furca pentru paleți pentru calitate superioară și eficiență!'
          },
          {
            id: 'konnyuanyag-kanal',
            name: 'Cupă pentru materiale ușoare',
            properties: [
              'Profil de cupă anti-scurgere, lamă de tăiere HARDOX 500, capacitate mare fără greutate suplimentară, opțiune cu lamă de tăiere fixată cu șuruburi',
              'Profil de cupă anti-scurgere',
              'Lamă de tăiere HARDOX 500',
              'Capacitate mare fără greutate suplimentară',
              'Opțiune cu lamă de tăiere fixată cu șuruburi'
            ],
            usage: 'Recomandată pentru încărcarea materialelor în vrac utilizate în agricultură și furaje granulare, atât în versiuni fixe cât și de descărcare înălțată.',
            text: 'Cupa pentru materiale ușoare este ideală pentru încărcarea și transportul materialelor ușoare, cum ar fi așchii de lemn, compost și deșeuri. Echipamentul este de înaltă calitate și extrem de rezistent la uzură, asigurând o durată lungă de viață. Este o alegere perfectă pentru lucrări eficiente și precise. Alegeți cupa pentru materiale ușoare pentru sarcinile de manipulare a materialelor ușoare!'
          },
          {
            id: 'rezsu-kanal',
            name: 'Cupă de profil',
            properties: [
              'Lamă de tăiere HARDOX 500',
              'Profil de cupă robust, unic',
              'Disponibil în mai multe profile',
            ],
            usage: 'Recomandată pentru săpături de profile, curățarea albiilor și șanțurilor.',
            text: 'Cupă de profil fixă este soluția perfectă pentru lucrările de terasament, în special pentru săparea șanțurilor, pantele și profilurilor de teren. Echipamentul este de înaltă calitate și extrem de rezistent la uzură, asigurând o durată lungă de viață. Permite o muncă mai precisă și eficientă. Alegeți cupa de profil fixă pentru lucrări de terasament precise și de încredere!',
          },
          {
            id: 'hidraulikus-rezsu-kanal',
            name: 'Cupă de profil hidraulic',
            properties: [
              'Lamă de tăiere și margine HARDOX 500',
              'Corp robust de cupă din oțel S355',
              'Mai multe fiare de întărire',
              'Profil modern de cupă',
              'Suprafață de netezire extensibilă fără costuri suplimentare',
              'Lucru precis cu debit ajustabil pentru protecția cilindrilor de concurență și pentru muncă precisă'
            ],
            usage: 'Recomandată pentru toate sarcinile de săparea profilului și atunci când eliminarea denivelărilor terenului nu este posibilă pentru mașină.',
            text: 'Cupă de profil hidraulic revoluționează formarea profilurilor și șanțurilor, permițând lucrări precise și eficiente pe orice șantier. Sistemul inovator hidraulic permite manevrarea ușoară și precisă, precum și lucrările de terasament de înaltă performanță pentru operator. Cupa fabricată din materiale de înaltă calitate asigură o rezistență excepțională la uzură și o durată lungă de viață pentru o utilizare îndelungată și de încredere.\nAlegeți modelul hidraulic pentru aplicabilitatea largă și flexibilitatea lucrărilor eficiente de terasament! Încredințați cele mai precise procese de lucru unui echipament care reprezintă vârful performanței și fiabilității pe orice șantier!'
          },
          {
            id: 'melyaso-kanal-korom-nelkul',
            name: 'Cupă de săpat adânc cu lamă de tăiere',
            properties: [
              'Profil de cupă proiectat pe baza feedback-ului operatorilor',
              'Lamă și margine HARDOX 500',
              'Disponibil în versiuni standard, întărită și extra întărită',
              'Pentru toate tipurile de excavatoare'
            ],
            usage: 'Recomandată pentru cele mai multe săpături sub nivelul solului și încărcare.',
            text: 'Cupă de săpat adânc este ideală pentru săpături adânci, cum ar fi fundațiile și șanțurile. Echipamentul fabricat conform cerințelor individuale este de înaltă calitate, fiabil și durabil. Este o alegere excelentă pentru lucrări de terasament precise și eficiente. Alegeți cupa de săpat adânc pentru lucrări profesionale și rezultate excelente!'
          },
          {
            id: 'rezsu-kanal-varos',
            name: 'Cupă de profil',
            properties: [
              'Lamă de tăiere HARDOX 500',
              'Profil de cupă robust, unic',
              'Disponibil în mai multe profile',
            ],
            usage: 'Recomandată pentru săpături de profile, curățarea albiilor și șanțurilor.',
            text: 'Cupă de profil fixă este soluția perfectă pentru lucrările de terasament, în special pentru săparea șanțurilor, pantele și profilurilor de teren. Echipamentul este de înaltă calitate și extrem de rezistent la uzură, asigurând o durată lungă de viață. Permite o muncă mai precisă și eficientă. Alegeți cupa de profil fixă pentru lucrări de terasament precise și de încredere!',
          },
          {
            id: 'melyaso-kanal',
            name: 'Cupă de săpat adânc cu gheară',
            properties: [
              'Profil de cupă proiectat pe baza feedback-ului operatorilor',
              'Lamă și margine HARDOX 500',
              'Sistem de gheare opțional',
              'Echipată cu benzi de uzură pe placa de burtă',
              'Disponibil în versiuni standard, întărită și extra întărită',
              'Pentru toate tipurile de excavatoare'
            ],
            usage: 'Recomandată pentru cele mai multe săpături sub nivelul solului și încărcare.',
            text: 'Cupă de săpat adânc este ideală pentru săpături adânci, cum ar fi fundațiile și șanțurile. Echipamentul fabricat conform cerințelor individuale este de înaltă calitate, fiabil și durabil. Este o alegere excelentă pentru lucrări de terasament precise și eficiente. Alegeți cupa de săpat adânc pentru lucrări profesionale și rezultate excelente!'
          },
          {
            id: 'trapez-kanal-foldmunka',
            name: 'Cupă trapezoidală',
            properties: [
              'Lamă HB 500',
              'Disponibilă cu margini înclinate și ajustabile hidraulic',
              'Profil proiectat pe baza feedback-ului operatorilor',
            ],
            usage: 'Recomandată pentru săparea șanțurilor - profiluri simetrice și asimetrice care nu necesită lucrări ulterioare.',
            text: 'Descoperiți versatilitatea cupei trapezoidale, care oferă soluții ideale pentru săparea, formarea și întreținerea șanțurilor, precum și pentru întreținerea canalelor! Fabricată din materiale de înaltă calitate și foarte rezistentă la uzură, asigurând o durată lungă de viață și fiabilitate pe parcursul lucrărilor. Îmbunătățiți nivelul lucrărilor dumneavoastră folosind cupa trapezoidală, care permite obținerea rezultatelor precise și eficiente în orice condiții. Cea mai bună alegere atunci când contează calitatea și performanța!'
          },
          {
            id: 'melyaso-kanal-korom-nelkul-kozmu',
            name: 'Cupă de săpat adânc cu lamă de tăiere',
            properties: [
              'Profil de cupă proiectat pe baza feedback-ului operatorilor',
              'Lamă și margine HARDOX 500',
              'Disponibil în versiuni standard, întărită și extra întărită',
              'Pentru toate tipurile de excavatoare'
            ],
            usage: 'Recomandată pentru cele mai multe săpături sub nivelul solului și încărcare.',
            text: 'Cupă de săpat adânc este ideală pentru săpături adânci, cum ar fi fundațiile și șanțurile. Echipamentul fabricat conform cerințelor individuale este de înaltă calitate, fiabil și durabil. Este o alegere excelentă pentru lucrări de terasament precise și eficiente. Alegeți cupa de săpat adânc pentru lucrări profesionale și rezultate excelente!'
          },
          {
            id: 'trapez-kanal-kozmuepites',
            name: 'Cupă trapezoidală',
            properties: [
              'Lamă HB 500',
              'Disponibilă cu margini înclinate și ajustabile hidraulic',
              'Profil proiectat pe baza feedback-ului operatorilor',
            ],
            usage: 'Recomandată pentru săparea șanțurilor - profiluri simetrice și asimetrice care nu necesită lucrări ulterioare.',
            text: 'Descoperiți versatilitatea cupei trapezoidale, care oferă soluții ideale pentru săparea, formarea și întreținerea șanțurilor, precum și pentru întreținerea canalelor! Fabricată din materiale de înaltă calitate și foarte rezistentă la uzură, asigurând o durată lungă de viață și fiabilitate pe parcursul lucrărilor. Îmbunătățiți nivelul lucrărilor dumneavoastră folosind cupa trapezoidală, care permite obținerea rezultatelor precise și eficiente în orice condiții. Cea mai bună alegere atunci când contează calitatea și performanța!'
          },
          {
            id: 'ripper',
            name: 'Rupător',
            properties: [
              'Corp principal din oțel structural S355',
              'Înveliș HARDOX 500',
              'Sistem de gheară ușor de schimbat',
              'Proiectare care se tocește constant ascuțit',
              'Compatibil cu orice tip de prindere'
            ],
            usage: 'Recomandat pentru afânarea solurilor dure, săpături de pâlcuri și pentru scoaterea materialelor încastrate în sol.',
            text: 'Descoperiți versatilitatea ruptorului, un instrument esențial nu doar pentru mini mașini, ci și pentru cele mai mari excavatoare. Acest echipament inovator permite afânarea solurilor dure, astfel încât materialele să poată fi încărcate ușor și eficient ulterior. Folosiți o soluție care garantează eficiență și productivitate pe orice teren de lucru!'
          },
          {
            id: 'hidraulikus-rezsu-kanal-kozmu',
            name: 'Cupă de profil hidraulic',
            properties: [
              'Lamă de tăiere și margine HARDOX 500',
              'Corp robust de cupă din oțel S355',
              'Mai multe fiare de întărire',
              'Profil modern de cupă',
              'Suprafață de netezire extensibilă fără costuri suplimentare',
              'Lucru precis cu debit ajustabil pentru protecția cilindrilor de concurență și pentru muncă precisă'
            ],
            usage: 'Recomandată pentru toate sarcinile de săparea profilului și atunci când eliminarea denivelărilor terenului nu este posibilă pentru mașină.',
            text: 'Cupă de profil hidraulic revoluționează formarea profilurilor și șanțurilor, permițând lucrări precise și eficiente pe orice șantier. Sistemul inovator hidraulic permite manevrarea ușoară și precisă, precum și lucrările de terasament de înaltă performanță pentru operator. Cupa fabricată din materiale de înaltă calitate asigură o rezistență excepțională la uzură și o durată lungă de viață pentru o utilizare îndelungată și de încredere.\nAlegeți modelul hidraulic pentru aplicabilitatea largă și flexibilitatea lucrărilor eficiente de terasament! Încredințați cele mai precise procese de lucru unui echipament care reprezintă vârful performanței și fiabilității pe orice șantier!'
          },
          {
            id: 'erositett-melyaso-kanal',
            name: 'Cupă de mină',
            properties: [
              'Corp de cupă Hardox HiTuf 500',
              'Suduri realizate cu material de adaos rezistent la uzură',
              'Pentru cele mai dificile sarcini de minerit',
              'Echipament personalizat'
            ],
            usage: 'Recomandată pentru cele mai dificile sarcini de minerit și pentru solicitările mari.',
            text: 'Cupă de mină este proiectată special pentru utilizare în medii de minerit și industrie grea, pentru transportul cantităților mari de materiale. Echipamentul este de înaltă calitate și extrem de rezistent la uzură, asigurând o durată lungă de viață. Performanța sa ajută la realizarea sarcinilor mai grele de transport de materiale. Alegeți cupa de mină pentru transport de materiale fiabil și durabil!'
          },
          {
            id: 'erositett-rakodo-kanal',
            name: 'Cupă de încărcare întărită (HDX)',
            properties: [
              'Proiectată pentru cele mai grele lucrări de încărcare minieră',
              'Disponibilă la dimensiuni personalizate pentru toate tipurile de mașini',
              'Toate componentele cupei pot fi echipate cu elemente și segmente rezistente la uzură',
              'Profil de cupă și formă a lamei personalizabile'
            ],
            usage: 'Proiectată pentru lucrări de încărcare minieră. Echipament personalizat conform cerințelor clientului.',
            text: 'Descoperiți performanța și durabilitatea maximă cu cupa de încărcare întărită, proiectată special pentru cele mai grele provocări miniere și industriale. Construcția robustă și componentele supradimensionate asigură performanțe excepționale chiar și în cele mai extreme condiții de lucru. Părțile expuse uzurii ale cupei sunt protejate cu plăci speciale rezistente la uzură, în timp ce marginea întărită asigură protecție maximă și durabilitate.'
          },
          {
            id: 'rakodo-kanal',
            name: 'Cupă de încărcare',
            properties: [
              'Pentru toate tipurile de încărcătoare',
              'Disponibilă cu corp de cupă HARDOX 500',
              'Sistem de gheare opțional de orice dimensiune',
              'Disponibilă în versiuni deschise și de descărcare înălțată',
              'Fabricată în versiuni standard, întărită și minieră'
            ],
            usage: 'Recomandată în principal pentru sarcini de încărcare de la greutăți mici la mari. Pentru toate tipurile de mașini. Echipament variabil cu lamă sau sistem de gheare, în funcție de materialul încărcat.',
            text: 'Cupă de încărcare este versatilă pentru încărcarea și transportul materialelor precum nisip, pietriș și pământ. Echipamentul personalizat este adaptat exact nevoilor dvs., fiind de înaltă calitate. Fiabilitatea și durabilitatea cresc eficiența muncii. Alegeți cupa de încărcare pentru toate sarcinile de încărcare!'
          },
          {
            id: 'ripper-banyaszat',
            name: 'Rupător',
            properties: [
              'Corp principal din oțel structural S355',
              'Înveliș HARDOX 500',
              'Sistem de gheară ușor de schimbat',
              'Proiectare care se tocește constant ascuțit',
              'Compatibil cu orice tip de prindere'
            ],
            usage: 'Recomandat pentru afânarea solurilor dure, săpături de pâlcuri și pentru scoaterea materialelor încastrate în sol.',
            text: 'Descoperiți versatilitatea ruptorului, un instrument esențial nu doar pentru mini mașini, ci și pentru cele mai mari excavatoare. Acest echipament inovator permite afânarea solurilor dure, astfel încât materialele să poată fi încărcate ușor și eficient ulterior. Folosiți o soluție care garantează eficiență și productivitate pe orice teren de lucru!'
          },
          {
            id: 'hidraulikus-kotro-XCMG-gyorscserelo',
            name: 'Schimbător rapid de excavator hidraulic / mecanic',
            properties: [
              'Un echipament esențial pentru versatilitatea excavatorului. Economie de timp, muncă umană și forță motrice hidraulică și mecanică pentru toate tipurile de utilaje.',
              'Un echipament esențial pentru versatilitatea excavatorului',
              'Economie de timp, muncă umană și forță motrice',
              'Disponibil în variante hidraulice și mecanice',
              'Pentru toate tipurile de utilaje'
            ],
            usage: 'Recomandat pentru excavatoarele utilizate pentru scopuri multiple, pentru schimburi frecvente de echipamente. În lume în continuă dezvoltare, este practic dotarea de bază pentru un excavator.',
            text: 'Schimbătorul rapid de excavator hidraulic permite schimbarea rapidă a sculelor excavatorului, crescând semnificativ versatilitatea mașinii. Echipamentul personalizat este de înaltă calitate și durabil. Cu ajutorul său puteți efectua lucrările de terasament mai rapid și mai eficient. Alegeți schimbătorul rapid de excavator hidraulic pentru muncă flexibilă și rapidă!'
          },
          {
            id: 'hidraulikus-rakodo-VOLVO-gyorscserelo',
            name: 'Schimbător rapid de încărcător hidraulic',
            properties: [
              'Permite utilizarea multiplă a încărcătorului.',
            ],
            usage: 'Recomandat pentru încărcătoarele cu utilizare multiplă și schimburi frecvente de echipamente.',
            text: 'Un echipament de bază pentru excavarea șanțurilor, canalelor, și profilurilor.'
          },
          {
            id: 'balavilla',
            name: 'Furcă pentru baloți',
            properties: [
              'Accesoriu cu profil propriu',
              'Design simplu și costuri eficiente',
              'Tije ușor de înlocuit',
              'Compatibilă cu orice tip de prindere'
            ],
            usage: 'Recomandat pentru sectorul agricol pentru manipularea baloților.',
            text: 'Furca pentru balo'
          },
          {
            id: 'tragyavilla',
            name: 'Furcă pentru gunoi',
            properties: [
              'Corp robust și simplu din oțel structural S355',
              'Echipată cu o linie de furci interschimbabile',
              'Disponibilă în versiune cu crocodil hidraulic',
              'Disponibilă și într-o versiune simplă cu furci inferioare'
            ],
            usage: 'Recomandată pentru lucrări agricole, manipularea gunoiului și încărcarea deșeurilor vegetale fibroase.',
            text: 'Furca pentru gunoi este excelentă pentru gestionarea și încărcarea gunoiului pe terenurile agricole. Produsul fabricat conform cerințelor individuale este de înaltă calitate, fiabil și durabil. Vă permite să îndepliniți sarcinile de manipulare a gunoiului în mod eficient și ușor. Fiți parte a evoluției eficienței mecanizării agricole.'
          },
          {
            id: 'pap1eco',
            category: 'blowingMachine',
            name: 'PAP-1 ECO',
            image: 'PAP-1-ECO.jpg',
            values: ['1', '0.75 L', '800'],
            overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          },
          {
            id: 'pap1',
            category: 'blowingMachine',
            name: 'PAP-1',
            image: 'PAP-1.jpg',
            values: ['1', '0.75 L', '900'],
            overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          },
          {
            id: 'pap1hotFill',
            category: 'blowingMachine',
            name: 'PAP-1 HOT FILL',
            image: 'PAP-1.jpg',
            values: ['1', '0.75 L', '650'],
            overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          },
          {
            id: 'pa1eco',
            category: 'blowingMachine',
            name: 'PA-1 ECO',
            image: 'dummy_300x150.png',
            values: ['1', '2.25 L', '800'],
            overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          },
          {
            id: 'pa1hotFill',
            category: 'blowingMachine',
            name: 'PA-1 HOT FILL',
            image: 'dummy_300x150.png',
            values: ['1', '2.25 L', '650'],
            overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          },
          {
            id: 'pe15uni',
            category: 'blowingMachine',
            name: 'PE-15 UNI',
            image: 'PE-15-UNI.jpg',
            values: ['1', '6 L', '1300'],
            overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          },
          {
            id: 'pe110uni',
            category: 'blowingMachine',
            name: 'PE-110 UNI',
            image: 'PE-110-UNI.jpg',
            values: ['1', '12 L', '1200',],
            overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          },
          {
            id: 'pap2',
            category: 'blowingMachine',
            name: 'PAP-2',
            image: 'PAP-1.jpg',
            values: ['2', '0.3 L', '1800',],
            overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          },
          {
            id: 'pa2ecoplus',
            category: 'blowingMachine',
            name: 'PA-2 ECO+',
            image: 'PA-2-ECO.jpg',
            values: ['2', '2 L', '1400',],
            overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          },
          {
            id: 'pe2uni',
            category: 'blowingMachine',
            name: 'PE-2 UNI',
            image: 'PE-2-UNI-és-PE-2-UNI-HOTFILL.jpg',
            values: ['2', '2.5 L', '3200',],
            overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          },
          {
            id: 'pe2uniHotFill',
            category: 'blowingMachine',
            name: 'PE-2 UNI HOT FILL',
            image: 'PE-2-UNI-és-PE-2-UNI-HOTFILL.jpg',
            values: ['2', '2.5 L', '1800',],
            overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          },
          {
            id: 'pe25uni',
            category: 'blowingMachine',
            name: 'PE-25 UNI',
            image: 'PE-25-UNI-és-PA-210.jpg',
            values: ['2', '6 L', '2500',],
            overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          },
          {
            id: 'pa210uni',
            category: 'blowingMachine',
            name: 'PA-210 UNI',
            image: 'PE-25-UNI-és-PA-210.jpg',
            values: ['2', '12 L', '2000 bph',],
            overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          },
          {
            id: 'pe4uni',
            category: 'blowingMachine',
            name: 'PE-4 UNI',
            image: 'PE-4-UNI.jpg',
            values: ['4', '2.5 L', '6400',],
            overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          },
          {
            id: 'pe6uni',
            category: 'blowingMachine',
            name: 'PE-6 UNI',
            image: 'dummy_300x150.png',
            values: ['6', '2.5 L', '9200 bph',],
            overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          },
          {
            id: 'pe12uni',
            category: 'blowingMachine',
            name: 'PE-12 UNI',
            image: 'PE-12-UNI.jpg',
            values: ['12', '0.06 L', '12000',],
            overlayText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          },
        ]
      },
    },
  },
  getters: {
    getProductTypes:(state) => (category) =>{
      return state.locale.current.prodGroupLocale.find(a => a.id === category).types;
    },
    getProducts:(state) => (params)=>{
      const { category, type } = params;
      if(type === undefined)
        return state.products.filter(a => a.category === category)
      else
        return state.products.filter(a => a.category === category && a.type === type)
    },
    getProductPropertyCategories:(state) => (params) =>{
      const { type } = params;
      if(type === 'blowingMachine'){
        return state.locale.current.bbmProdProperties;
      }
    },
    getProductTitle:(state) => (id) =>{
      return state.products.find(a => a.id === id).name
    },
    getProductType:(state) => (id) =>{
      return state.products.find(a => a.id === id).type
    },
    getProductMainImage:(state) => (id) =>{
      return state.products.find(a => a.id === id).image
    },
    getProductSideImage:(state) => (id) =>{
      return state.products.find(a => a.id === id).secondImage
    },
    getProductProperties:(state) => (id) =>{
      return state.products.find(a => a.id === id).properties
    },
    getProductUsage:(state) => (id) =>{
      return state.products.find(a => a.id === id).usage
    },
    getProductText:(state) => (id) =>{
      return state.products.find(a => a.id === id).text
    },
    getPruductReferencePics:(state) => (id) =>{
      return state.products.find(a => a.id === id).noOfRefPics
    }
  },
  mutations: {

    setScreenSize (state,n){
      state.screenSize = n
    },

    setSharedStyle(state,n){
      switch (n){
        case 'mobile':{
          state.sharedStyle["--titleFont"] = '40px';
          state.sharedStyle["--largeTitleFont"] = '43px';
          state.sharedStyle["--smallTitleFont"] = '37px';
          state.sharedStyle["--textFont"] = '19px';
          state.sharedStyle["--largeTextFont"] = '20px';
          state.sharedStyle["--smallTextFont"] = '16px';
          break;
        }
        case 'mobileL':{
          state.sharedStyle["--titleFont"] = '43px';
          state.sharedStyle["--largeTitleFont"] = '45px';
          state.sharedStyle["--smallTitleFont"] = '40px';
          state.sharedStyle["--textFont"] = '19px';
          state.sharedStyle["--largeTextFont"] = '20px';
          state.sharedStyle["--smallTextFont"] = '18px';
          break;
        }
        case 'tabletP':{
          state.sharedStyle["--titleFont"] = '47px';
          state.sharedStyle["--largeTitleFont"] = '48px';
          state.sharedStyle["--smallTitleFont"] = '43px';
          state.sharedStyle["--textFont"] = '20px';
          state.sharedStyle["--largeTextFont"] = '21px';
          state.sharedStyle["--smallTextFont"] = '19px';
          break;
        }
        case 'tabletL':{
          state.sharedStyle["--titleFont"] = '50px';
          state.sharedStyle["--largeTitleFont"] = '51px';
          state.sharedStyle["--smallTitleFont"] = '47px';
          state.sharedStyle["--textFont"] = '20px';
          state.sharedStyle["--largeTextFont"] = '21px';
          state.sharedStyle["--smallTextFont"] = '19px';
          break;
        }
        case 'laptop':{
          state.sharedStyle["--titleFont"] = '53px';
          state.sharedStyle["--largeTitleFont"] = '54px';
          state.sharedStyle["--smallTitleFont"] = '48px';
          state.sharedStyle["--textFont"] = '21px';
          state.sharedStyle["--largeTextFont"] = '22px';
          state.sharedStyle["--smallTextFont"] = '20px';
          break;
        }
        default:{
          break;
        }
      }
    },
    setFormVisibility(state,){
      state.showTheForm = !state.showTheForm;
    },
    setChosenTab(state,n){
      state.chosenTab = n;
    },
    setNavbarHeight(state){
      state.resetNavbarHeight = !state.resetNavbarHeight
    },
    setLanguage(state,n){
      //SET THE LANGUAGE VARIABLES
      state.locale.current = state.locale[n]
      //SET THE LANGUAGE MARKER
      state.chosenLang = n;
      //SET NAVBAR EXPRESSIONS
      state.sites = state.locale.current.sites

      //SET PRODUCT LOCALES
      //go through the products, look for the ID in the new locales, and set the name, properties, usage, text
      state.products.forEach((product)=>{
        //console.log(product.id)
        let newValues = state.locale.current.prodLocale.find((a)=>a.id===product.id)
        //console.log(newValues)
        if(newValues !== undefined){
          product.name=newValues.name
          product.properties=newValues.properties
          product.usage=newValues.usage
          product.text=newValues.text
          product.overlayText=newValues.overlayText
        }
      })
      //SET PRODUCT GROUP LOCALES
      state.productCategories = state.locale.current.prodGroupLocale

    }
  },

  actions: {
    overallSetting: ({commit}, payload) => {
      commit('setScreenSize', payload)
      commit('setSharedStyle', payload)
    },
    navigateTo({ commit }, route) {
      if(route === 'webshop'){
        window.open('https://flakon.hu', '_blank');
      }
      else{
        document.getElementById("navbar").style.height = "8dvh";
        document.getElementById("routerView").style.marginTop = "0dvh";
        document.getElementById("routerView").style.marginTop = "8dvh";

        // Perform route navigation
        commit('setChosenTab',route)
        router.push({name:route})
      }
    }
  },
  modules: {},
  plugins: [createPersistedState({
    key: 'myApp', // You can provide a custom key if needed
    // Custom configurations if necessary
    storage: window.localStorage, // or sessionStorage
  })]
})
