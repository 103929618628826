<template>
  <div :class=" $store.state.screenSize + ' wireFrameDesign'">
    <div id="innerContainer" class="grid" :class="[{w1300: $store.state.screenSize ==='desktop'},$store.state.screenSize === 'laptop'?'w1000 twoColumns':'',$store.state.screenSize==='tabletL'&&'w800 twoColumns']">
      <div id="leftSide" >
        <text-with-line-after :text="gl('hb5')"/>
        <h3 id="solutions">{{gl('wfd1')}}</h3>
        <read-more-button goto="bucket" :text="gl('rmb1')" identification="leftSideReadMore" :bordered="true" link: style="margin-top: 6%"></read-more-button>
        <img src="kanal_wireframe.webp">
      </div>
      <div id="rightSide">
        <div class="article" v-for="article in articles" v-bind:key="article.link">
          <h5 class="title">{{article.title}}</h5>
          <p class="text">{{article.text}}</p>
          <read-more-button goto="bucket" :text="gl('wfd8')" :identification="article.link"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ReadMoreButton from "@/components/ReadMoreButton.vue";
import TextWithLineAfter from "@/components/TextWithLineAfter.vue";

export default {
  name:'WireFrameDesign',
  components: {TextWithLineAfter, ReadMoreButton},
  data(){
    return{
      articles:[
        {
          title:this.gl('wfd2'),
          text:this.gl('wfd3'),
          link:'szerelekgyartas1'
        },
        {
          title:this.gl('wfd4'),
          text:this.gl('wfd5'),
          link:'szerelekgyartas'
        },
        {
          title:this.gl('wfd6'),
          text:this.gl('wfd7'),
          link:'szerelekgyartas2'},
      ]
    }
  },
  methods:{
    gl(what){
      return this.$store.state.locale.current[what]
    }
  }

}

</script>

<style scoped lang="scss">
.wireFrameDesign{
  background-color: rgb(21, 20, 28);
  #innerContainer{
    padding-top: 2%;
    display: grid;
    margin: 0 auto;
    grid-template-columns: 900px auto;
    gap: 7%;
    overflow: visible;
    #leftSide{
      #solutions{
        font-family: Inter-ExtraBold,sans-serif;
        font-size: var(--titleFont,60px);
        text-transform: uppercase;
        text-align: left;
        margin: unset;
        color: white;
      }
      img{
        width: 110%;
        margin-top: -25%;
        margin-left: -30%;
      }
    }
    #rightSide{
      .article {
        border-top: 1.5px #FFFFFF solid;
        text-align: left;
        padding-bottom: 14%;
        .title {
          text-transform: uppercase;
          letter-spacing: -.02em;
          font-size: var(--largeTextFont);
          color: white;
          margin-top: 5%;
          margin-bottom: 1%;
        }
        .text{
          width: 90%;
          font-size: var(--textFont);
          color: rgb(192,193,193);
          margin-bottom: 7%;
          margin-top: 3%;
        }
      }
      display: grid;
      height: min-content;
    }
  }
  &.mobile,
  &.tablet{
    height: unset;
    padding-bottom: 80px;
    padding-top: 80px;
    #innerContainer{
      width: 85%;
      grid-template-columns: 1fr;
      #leftSide{
        position: relative;
        img{
          position: absolute;
          margin: unset;
          right: 0;
          top: 0;
          width: 150%;
          z-index: 1;
        }
      }
      #rightSide{
        padding-top: 30px;
        z-index: 2;
      }
    }
  }
  &.tablet{
    #innerContainer{
      margin-right: 3%;
      #rightSide{
        width: 60%;
        justify-self: right;
      }
    }
  }
}
</style>