<template>
  <div :class="[$store.state.screenSize,'photoMontagePortrait', {w1000:['laptop','tabletL'].includes($store.state.screenSize)},{w1300:$store.state.screenSize === 'desktop'}]">
    <text-with-line-after :text="gl('hb1')"/>
    <h2 class="title">{{gl('pmp1')}}</h2>
    <div class="photos" @click="$store.dispatch('navigateTo','flakonfuvogep')">
      <div class="photo" style="background-image: url('gep_belso.webp'); grid-area: photo0;">
        <h5 class="photo-title">{{gl('pmp2')}}</h5>
        <h6 class="photo-text">{{gl('pmp3')}}</h6>
      </div>
      <div class="photo" style="background-image: url( 'gep2.jpg'); grid-area: photo1; background-position: left">
        <h5 class="photo-title">{{gl('pmp4')}}</h5>
        <h6 class="photo-text">{{gl('pmp5')}}</h6>
      </div>
      <div class="photo" style="background-image: url( 'Erős váz és zárszerkezet.png'); grid-area: photo2;">
        <h5 class="photo-title">{{gl('pmp6')}}</h5>
        <h6 class="photo-text">{{gl('pmp7')}}</h6>
      </div>
      <div class="photo" style="background-image: url('sarga-zold-mosott-hatterrel.webp'); grid-area: photo3;">
        <h5 class="photo-title" style="bottom: 90px;">{{gl('pmp8')}}</h5>
        <h6 class="photo-text" style="bottom: 10px">{{gl('pmp9')}}</h6>
      </div>
    </div>
  </div>
</template>
<script>
import TextWithLineAfter from "@/components/TextWithLineAfter.vue";

export default {
  name: "PhotoMontagePortrait",
  components: {TextWithLineAfter},
  data(){
    return{
    }
  },
  methods:{
    gl(what){
      return this.$store.state.locale.current[what]
    }
  }
}
</script>

<style scoped lang="scss">
.photoMontagePortrait{
  position: relative;
  padding-top: 158px;

  margin: 0 auto;
  margin-bottom: 7%;
  .title{
    font-family: Inter-ExtraBold,sans-serif;
    text-transform: uppercase;
    font-size: var(--titleFont);
    text-align: left;
    width: 900px;
    margin: unset;
    margin-bottom: 2%;
  }
  .photos{
    height: 2000px;
    padding-left: 6%;
    display: grid;
    grid-template-areas:
    "photo0 ."
    "photo0 photo1"
    "photo2 photo1"
    "photo2 photo3"
    "text photo3";
    gap: 80px;
    .photo{
      transition: 1s ease;
      height: 100%;
      background-size: cover;
      background-position: center;
      width:100%;
      grid-row: span 2;
      display: grid;
      align-items: end;
      box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
      0 6.7px 5.3px rgba(0, 0, 0, 0.048),
      0 12.5px 10px rgba(0, 0, 0, 0.06),
      0 22.3px 17.9px rgba(0, 0, 0, 0.072),
      0 41.8px 33.4px rgba(0, 0, 0, 0.086),
      0 100px 80px rgba(0, 0, 0, 0.12);
      grid-template-rows: auto 20%;
      text-align: left;
      &:hover{
        transform: scale(1.05);
        cursor: pointer;
      }

      .photo-title{
        font-family: Space-Grotesk,sans-serif;
        font-size: var(--largeTextFont);
        line-height: 1.2em;
        color: white;
        text-shadow: black 2px 4px 5px;
        text-transform: uppercase;
        letter-spacing: -.02em;
        padding: 30px;

        /*position: absolute;
        bottom: 80px;
        left: 4%;*/
      }
      .photo-text{
        font-family: Inter, sans-serif;
        font-size: var(--smallTextFont);
        line-height: 24px;
        font-weight: 700;
        text-transform: uppercase;
        letter-spacing: -.02em;
        vertical-align: middle;
        /*position: absolute;
        bottom:30px;*/
        text-align: left;
        left: 4%;
        color: white;
        text-shadow: black 2px 4px 5px;
        padding: 30px;
      }
    }

  }

  &.mobile,
  &.tablet{
    width: 88%;
    margin: 0 auto;
    .photos{
      grid-template-areas:
                  "photo0"
                  "photo1"
                  "photo2"
                  "photo3";
      grid-template-columns: 1fr;
      gap: 25px;
      .photo{
        text-align: left;
      }
    }
    .title{
      width: 100%;
      margin-bottom: 30px;
    }

  }
}
</style>