<template>
  <div class="h500 w500 p-top-30 pBot80">
    <carousel @slide-end="pageChanged"  :items-to-show="1" :wrap-around="true" :transition="1000">
      <Slide v-for="(slide, index) in noOfSlides"
             :key="index"
             :style="{backgroundImage:'url('+idOfProd+'-'+slide+'.jpg)', backgroundPosition:'center center'}" class="bgSizeCover bgRepeatNoRepeat"
             @click="toggleLarge"
      >
        <div :class="['carousel__item', 'titleItem', 'middleMargin','h500']"></div>
      </Slide>

      <template #addons>
        <Navigation />
      </template>
    </carousel>
    <div v-if="showLarge">
      <div class="overlay align-content-center"  @click="handleMouseUp">
      <carousel
                @click.stop=""
                @mousedown="handleMouseDown"
                @mousemove="handleMouseMove"
                ref="largeGallery"
                :items-to-show="1"
                :wrap-around="true"
                :transition="1000"
                :modelValue="currentSlide"
                class="middleMargin h60vh w60vh pos-rel zi-100"
      >
        <Slide v-for="(slide, index) in noOfSlides"
               :key="index"
               :style="{backgroundImage:'url('+idOfProd+'-'+slide+'.jpg)', backgroundPosition:'center center'}" class="bgSizeCover bgRepeatNoRepeat w60vh h60vh"
        >
          <div :class="['carousel__item', 'titleItem', 'middleMargin','h500']"></div>
          <close-button class="pos-vodka rig-0 top-0 zi-100" @close="showLarge = false"/>
        </Slide>
        <template #addons>
          <Navigation />
        </template>
      </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import CloseButton from "@/components/CloseButton.vue";

  export default {
    name: "PhotoGallery",
    props: {
      noOfSlides:{
        type:Number,
        required:true
      },
      idOfProd:{
        type:String,
        required: true
      }
    },
    data(){
      return{
        showLarge:false,
        currentSlide:0,
        dragging: false
      }
    },
    components: {
      CloseButton,
      Carousel,
      Slide,
      Navigation
    },
    methods: {
      pageChanged(newPage) {
        this.currentSlide = newPage.currentSlideIndex
      },
      toggleLarge() {
        this.showLarge = true

        //this.$refs.largeGallery.slideTo(this.currentSlide)
      },
      handleMouseDown() {
        this.dragging = false;
      },
      handleMouseUp() {
        if (!this.dragging) {
          this.showLarge = false
          // Your parent click logic here
        } else {

          this.dragging = false;
        }
      },
      handleMouseMove(event) {
        if (event.buttons === 1) {
          this.dragging = true;
        }
      },
      handleEscapeKey(event) {
        if (event.key === 'Escape' || event.key === 'Esc') {
          this.showLarge = false;
          // Your logic here, e.g., closing a modal, cancelling an action, etc.
        }
      }
    },
      mounted() {
        document.addEventListener('keydown', this.handleEscapeKey);
      },
      beforeUnmount() {
        document.removeEventListener('keydown', this.handleEscapeKey);
      }
  }
</script>

<style scoped lang="scss">

</style>